import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getCmdbObjectTypeByIdPositionUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/object-type.tsx';
import type { CmdbObjectTypeId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type { ActionApi } from '../../common/types';
import { fetchObjectTypes } from '../../services/fetch-object-types';

type UpdatePositionRequest = {
	position: number;
	// Omitted means no parent / root object type
	toObjectTypeId?: CmdbObjectTypeId;
};

export const updateObjectTypePosition =
	({
		movedObjectTypeId,
		newPosition,
		newParentObjectTypeId,
	}: {
		movedObjectTypeId: CmdbObjectTypeId;
		newPosition: number;
		newParentObjectTypeId: CmdbObjectTypeId | null;
	}): ActionApi =>
	async ({ getState, setState }, { workspaceId, objectSchemaId, createAnalyticsEvent }) => {
		try {
			const updateRequest: UpdatePositionRequest = {
				position: newPosition,
			};
			if (newParentObjectTypeId !== null) {
				updateRequest.toObjectTypeId = newParentObjectTypeId;
			}
			await performPostRequest(getCmdbObjectTypeByIdPositionUrl(workspaceId, movedObjectTypeId), {
				body: JSON.stringify(updateRequest),
			});
			const objectTypesById = await fetchObjectTypes(workspaceId, objectSchemaId);

			const { pageDataState } = getState();
			if (pageDataState.type !== 'success') {
				throw new Error(
					`pageDataState.type value: "${pageDataState.type}" did not match expected: "success"`,
				);
			}

			setState({
				pageDataState: {
					...pageDataState,
					objectTypesById,
				},
			});
			fireTrackAnalytics(createAnalyticsEvent({}), 'updateObjectTypePosition succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics({
				meta: {
					id: 'updateObjectTypePosition',
					packageName: 'jiraServicedeskInsightObjectSchemaPageStore',
					teamName: 'falcons',
				},
				error,
			});
			// Re-throw error so that consumers can know if the action succeeded
			throw error;
		}
	};
