import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { CmdbAsyncActionsContainer } from '@atlassian/jira-servicedesk-cmdb-async-actions/src/ui/index.tsx';
import { APP_NAMES } from '@atlassian/jira-servicedesk-common/src/utils/app-names';
import { InsightObjectSchemaPageContainer } from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/ui/index.tsx';
import { ObjectSchemaPage as InsightObjectSchemaPage } from '@atlassian/jira-servicedesk-insight-object-schema-page/src/ui/index.tsx';
import { toSchemaId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import {
	getRootPath,
	isInsightRoute,
	createObjectSchemasUrl,
	createObjectSchemaUrl,
} from '@atlassian/jira-servicedesk-insight-urls';
import ServiceDeskAppBase from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/app-base/index.tsx';
import { InsightAppBaseV2 } from '@atlassian/jira-spa-apps-insight-app-base-v2/src/ui/index.tsx';
import { Redirect, useRouter, usePathParam } from '@atlassian/react-resource-router';
import { useObjectTypeIdQueryParam, useObjectIdQueryParam, createQueryStringParams } from './utils';

export const CmdbObjectSchemaV2ViewOld = () => {
	const [objectSchemaId] = usePathParam('objectSchemaId');
	const { createAnalyticsEvent } = useAnalyticsEvents();

	if (!objectSchemaId) {
		return <Redirect to={createObjectSchemasUrl()} />;
	}

	return (
		<ServiceDeskAppBase appName={APP_NAMES.INSIGHT_OBJECT_SCHEMA}>
			<InsightAppBaseV2>
				{({ workspaceId }) => (
					<InsightObjectSchemaPageContainer
						workspaceId={workspaceId}
						objectSchemaId={toSchemaId(objectSchemaId)}
						createAnalyticsEvent={createAnalyticsEvent}
					>
						<InsightObjectSchemaPage />
					</InsightObjectSchemaPageContainer>
				)}
			</InsightAppBaseV2>
		</ServiceDeskAppBase>
	);
};

export const CmdbObjectSchemaV2View = () => {
	const [objectSchemaId] = usePathParam('objectSchemaId');
	const { createAnalyticsEvent } = useAnalyticsEvents();

	if (!objectSchemaId) {
		return <Redirect to={createObjectSchemasUrl()} />;
	}

	return (
		<ServiceDeskAppBase appName={APP_NAMES.INSIGHT_OBJECT_SCHEMA}>
			<InsightAppBaseV2>
				{({ workspaceId }) => (
					<InsightObjectSchemaPageContainer
						workspaceId={workspaceId}
						objectSchemaId={toSchemaId(objectSchemaId)}
						createAnalyticsEvent={createAnalyticsEvent}
					>
						<CmdbAsyncActionsContainer workspaceId={workspaceId}>
							<InsightObjectSchemaPage />
						</CmdbAsyncActionsContainer>
					</InsightObjectSchemaPageContainer>
				)}
			</InsightAppBaseV2>
		</ServiceDeskAppBase>
	);
};

export const ConditionalRedirectOrRouteView = () => {
	const [objectSchemaId] = usePathParam('objectSchemaId');
	const [typeId] = useObjectTypeIdQueryParam();
	const [objectId] = useObjectIdQueryParam();
	const [{ location, route }] = useRouter();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	if (isInsightRoute(location.pathname) && objectSchemaId) {
		fireOperationalAnalytics(
			createAnalyticsEvent({ action: 'redirected', actionSubject: 'routeView' }),
			'jsmCmdbAssetsRouteRedirect object-schema',
			{
				redirectedFrom: route.path,
				redirectedTo: `${getRootPath()}/object-schema/:objectSchemaId`,
			},
		);

		return (
			<Redirect
				to={`${createObjectSchemaUrl(toSchemaId(objectSchemaId))}${createQueryStringParams(
					typeId,
					objectId,
				)}`}
			/>
		);
	}

	return fg('assets_rearch_async_actions') ? (
		<CmdbObjectSchemaV2View />
	) : (
		<CmdbObjectSchemaV2ViewOld />
	);
};

export default ConditionalRedirectOrRouteView;
