import type { ObjectType as InsightObjectType } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/object-type.tsx';
import type {
	ReferenceType,
	ReferenceObjectType,
	ObjectTypeAttributeResponse as AttributeTypeBase,
} from './object-details';

export const AttributeTypeNumbers = {
	default: 0,
	object: 1,
	user: 2,
	group: 4,
	project: 6,
	status: 7,
	bitbucketRepo: 8,
	opsgenieTeam: 9,
} as const;

export type AttributeTypeNumbers = (typeof AttributeTypeNumbers)[keyof typeof AttributeTypeNumbers];

export type DefaultType = 'default';
export type ObjectType = 'object';
export type UserType = 'user';
export type GroupType = 'group';
export type ProjectType = 'project';
export type BitbucketRepoType = 'bitbucketRepo';
export type StatusType = 'status';
export type OpsgenieTeamType = 'opsgenieTeam';

export type AttributeType =
	| DefaultType
	| ObjectType
	| UserType
	| GroupType
	| ProjectType
	| BitbucketRepoType
	| StatusType
	| OpsgenieTeamType;

export type ObjectAttributeTypeResponse = AttributeTypeBase & {
	objectType: InsightObjectType;
};

export type DefaultAttributeType = Omit<
	AttributeTypeBase,
	'type' | 'suffix' | 'regexValidation' | 'defaultType'
> & {
	type: DefaultType;
	// present for int and float default attribute types that have a display view suffix configured
	suffix?: string;
	// present for select default attribute when options is populated
	regexValidation?: string;
	defaultType: {
		id: number;
		name: string;
	};
};

export type ObjectAttributeType = Omit<
	AttributeTypeBase,
	'type' | 'objectType' | 'referenceType' | 'referenceObjectType'
> & {
	type: ObjectType;
	objectType?: InsightObjectType;
	referenceType: ReferenceType;
	referenceObjectType: ReferenceObjectType;
};

export type UserAttributeType = Omit<AttributeTypeBase, 'type'> & {
	type: UserType;
};

export type GroupAttributeType = Omit<AttributeTypeBase, 'type'> & {
	type: GroupType;
};

export type ProjectAttributeType = Omit<AttributeTypeBase, 'type'> & {
	type: ProjectType;
};

export type BitbucketRepoAttributeType = Omit<AttributeTypeBase, 'type'> & {
	type: BitbucketRepoType;
};

export type StatusAttributeType = Omit<AttributeTypeBase, 'type'> & {
	type: StatusType;
};

export type OpsgenieTeamAttributeType = Omit<AttributeTypeBase, 'type'> & {
	type: OpsgenieTeamType;
};

export type InsightObjectAttributeType =
	| DefaultAttributeType
	| ObjectAttributeType
	| UserAttributeType
	| GroupAttributeType
	| ProjectAttributeType
	| BitbucketRepoAttributeType
	| StatusAttributeType
	| OpsgenieTeamAttributeType;
