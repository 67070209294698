import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getCmdbObjectQueryLanguageTotalCountUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/object.tsx';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import { createTypesafeErrorForAnalytics } from '@atlassian/jira-servicedesk-insight-shared-utils/src/common/utils/typesafe-error/index.tsx';
import type {
	ActionApi,
	AsyncSuccess,
	SearchStateValue,
	UncappedNumObjects,
} from '../../../../common/types';
import type { SearchObjectTotalCountResponse } from '../../../../common/types/responses';
import {
	checkIsSupportedSearchScope,
	generateQlQueryForSearchScope,
} from '../../../../common/utils';

export const fetchCmdbObjectQueryLanguageTotalCount = async (
	workspaceId: WorkspaceId,
	qlQuery: string,
) =>
	performPostRequest<SearchObjectTotalCountResponse>(
		getCmdbObjectQueryLanguageTotalCountUrl(workspaceId),
		{ body: JSON.stringify({ qlQuery }) },
	);

const updateUncappedNumObjectsState = (
	lastSearch: AsyncSuccess<SearchStateValue>,
	uncappedNumObjects: UncappedNumObjects,
) => ({
	lastSearch: {
		...lastSearch,
		value: {
			...lastSearch.value,
			result: {
				...lastSearch.value.result,
				uncappedNumObjects,
			},
		},
	},
});

export const fetchUncappedCount =
	(): ActionApi =>
	async ({ getState, setState }, { workspaceId, createAnalyticsEvent }) => {
		const { lastSearch, currentSearchScope } = getState();
		if (lastSearch.type !== 'success' || !currentSearchScope) {
			return;
		}
		setState(
			updateUncappedNumObjectsState(lastSearch, {
				totalCount: undefined,
				loading: true,
				error: undefined,
			}),
		);
		try {
			const lastQlQueryString = lastSearch.value.result.qlQuery;
			const { objectTypeId, objectSchemaId, isObjectTypeInherited } =
				checkIsSupportedSearchScope(currentSearchScope);
			const qlQuery = generateQlQueryForSearchScope(lastQlQueryString, {
				objectTypeId,
				objectSchemaId,
				isObjectTypeInherited,
			});
			const response = await fetchCmdbObjectQueryLanguageTotalCount(workspaceId, qlQuery);
			fireTrackAnalytics(createAnalyticsEvent({}), 'fetchUncappedCount succeeded');
			setState(
				updateUncappedNumObjectsState(lastSearch, {
					totalCount: response.totalCount,
					loading: false,
					error: undefined,
				}),
			);
		} catch (err: unknown) {
			const typesafeError = createTypesafeErrorForAnalytics(err);
			setState(
				updateUncappedNumObjectsState(lastSearch, {
					totalCount: undefined,
					loading: false,
					error: typesafeError.error,
				}),
			);
			fireErrorAnalytics({
				meta: {
					id: 'fetchUncappedCount',
					packageName: 'jiraServicedeskInsightObjectSearchStore',
					teamName: 'falcons',
				},
				error: typesafeError.isSafeForAnalytics ? typesafeError.error : undefined,
			});
		}
	};
