import type { Column } from '@atlassian/jira-servicedesk-insight-object-search-store/src/common/types/index.tsx';
import {
	insightObjectSearchLocalStorage,
	OBJECT_TYPE,
	SELECTED_COLUMNS,
} from '@atlassian/jira-servicedesk-insight-object-search-store/src/common/utils/storage.tsx';
import type { CmdbObjectTypeId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';

const AVATAR_COLUMN_ID = 'avatar';

export const setLocalSelectedColumns = (
	objectTypeId: CmdbObjectTypeId,
	updatedColumns: Column[],
): void => {
	const selectedColumns = updatedColumns.filter((column) => column.isSelected);
	const localRecordList: string[] = selectedColumns.map((column) =>
		column.target.type === AVATAR_COLUMN_ID ? 'icon' : column.target.id,
	);

	let localObjectTypeRecord = insightObjectSearchLocalStorage.get(OBJECT_TYPE + objectTypeId);
	if (localObjectTypeRecord) {
		localObjectTypeRecord[SELECTED_COLUMNS] = localRecordList;
	} else {
		localObjectTypeRecord = {
			[SELECTED_COLUMNS]: localRecordList,
		};
	}
	insightObjectSearchLocalStorage.set(OBJECT_TYPE + objectTypeId, localObjectTypeRecord);
};
