import React, { useMemo, useCallback } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Box } from '@atlaskit/primitives';
import Tabs, { Tab, TabList } from '@atlaskit/tabs';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	useLoadedSchemaPageData,
	useSchemaPageUiState,
} from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services';
import { canAccessAttributes } from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services/selectors/index.tsx';
import { VIEW_MODES, VIEW_MODES_MAP } from './constants';
import { messages } from './messages';

export const PageTabs = () => {
	const { formatMessage } = useIntl();
	const [{ schemaViewMode, selectedObjectTypeId }, { setSchemaViewMode }] = useSchemaPageUiState();
	const [schemaPageData] = useLoadedSchemaPageData();
	// TODO: investigate possible use of content prop in tabItems
	const tabItems = useMemo(() => {
		const tabs = [{ label: formatMessage(messages.objects) }];

		const canShowAttributes =
			selectedObjectTypeId !== null && canAccessAttributes(schemaPageData, selectedObjectTypeId);
		if (canShowAttributes) {
			tabs.push({ label: formatMessage(messages.attributes) });
		}
		return tabs;
	}, [formatMessage, selectedObjectTypeId, schemaPageData]);

	const handleChange = useCallback(
		(index: number, analyticsEvent: UIAnalyticsEvent) => {
			if (index === VIEW_MODES_MAP.object) {
				fireUIAnalytics(analyticsEvent, 'viewInsightObjects');
			}
			setSchemaViewMode(VIEW_MODES[index]);
		},
		[setSchemaViewMode],
	);

	return (
		<Box>
			<Tabs
				selected={VIEW_MODES_MAP[schemaViewMode]}
				onChange={handleChange}
				id="insight-object-schema-page-tabs"
				testId="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-tabs.tabs"
				shouldUnmountTabPanelOnChange
			>
				<TabList>
					{tabItems.map((tab, index) => (
						<Tab key={index}>{tab.label}</Tab>
					))}
				</TabList>
			</Tabs>
		</Box>
	);
};
