import { useEffect } from 'react';
import { useIntercom } from '@atlassian/jira-intercom-wrapper/src/controllers/index.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import {
	useAnalyticsEvents,
	fireTrackAnalytics,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useIntercomHmac } from '../../service';
import { INTERCOM_APP_ID } from './constants';

const getEventName = (eventName: string, isTargetUserOnPremiumTrial?: boolean): string => {
	return isTargetUserOnPremiumTrial ? `${eventName} premiumTrial` : eventName;
};

export const IntercomLoader = ({
	isTargetUserOnPremiumTrial,
}: {
	isTargetUserOnPremiumTrial?: boolean;
}) => {
	const [{ isIntercomInitialized }, { initialize, boot, trackEvent, onShow, shutdown }] =
		useIntercom();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { intercomHmac } = useIntercomHmac();

	const user = useCurrentUser()?.data?.user || {};

	const { cloudId, atlassianAccountId, userFullname } = useTenantContext();

	useEffect(() => {
		if (!atlassianAccountId || !intercomHmac) {
			return;
		}

		if (!isIntercomInitialized) {
			initialize({ appId: INTERCOM_APP_ID });
		}

		if (isIntercomInitialized) {
			boot(
				{
					user_id: atlassianAccountId,
					name: userFullname || '',
					// @ts-expect-error - TS2339 - Property 'emailAddress' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'. | TS2339 - Property 'emailAddress' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
					email: user?.emailAddress || '',
					user_hash: intercomHmac,
					company: {
						company_id: cloudId,
					},
				},
				INTERCOM_APP_ID,
			);

			onShow(() => {
				trackEvent(getEventName('intercomChatButton clicked', isTargetUserOnPremiumTrial));
				fireUIAnalytics(
					createAnalyticsEvent({
						action: 'clicked',
						actionSubject: 'intercomChatButton',
					}),
					'intercomMessengerLauncher',
					{ premiumTrial: isTargetUserOnPremiumTrial ?? false },
				);
			});

			trackEvent(getEventName('intercomChatButton viewed', isTargetUserOnPremiumTrial));
			fireTrackAnalytics(
				createAnalyticsEvent({}),
				'intercomChatButton viewed',
				'intercomMessengerLauncher',
				{ premiumTrial: isTargetUserOnPremiumTrial ?? false },
			);
		}

		return () => {
			shutdown();
		};
	}, [
		intercomHmac,
		isIntercomInitialized,
		atlassianAccountId,
		userFullname,
		// @ts-expect-error - TS2339 - Property 'emailAddress' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'. | TS2339 - Property 'emailAddress' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
		user?.emailAddress,
		cloudId,
		createAnalyticsEvent,
		boot,
		initialize,
		onShow,
		shutdown,
		trackEvent,
		isTargetUserOnPremiumTrial,
	]);
	return null;
};
