import React, { useLayoutEffect } from 'react';
import { objectDetailsViewExperience } from '@atlassian/jira-servicedesk-insight-experiences';
import { InsightObjectSchemaDetailsSkeleton } from '@atlassian/jira-skeletons/src/ui/insight-object-schema/index.tsx';
import type { ObjectSkeletonProps } from '../../types';

export const ObjectDetailsSkeleton = ({ startExperience }: ObjectSkeletonProps) => {
	useLayoutEffect(() => {
		if (startExperience) {
			objectDetailsViewExperience.abort();
			objectDetailsViewExperience.start();
		}
	}, [startExperience]);
	return <InsightObjectSchemaDetailsSkeleton />;
};
