import React from 'react';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { AsyncAssetsSchemaGraphModal } from '@atlassian/jira-servicedesk-cmdb-schema-graph/src/async';
import {
	useInsightObjectSchemaPageData,
	useSchemaPageUiState,
} from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services/index.tsx';

export const SchemaGraphModal = () => {
	const [{ objectSchemaId, selectedObjectTypeId }] = useSchemaPageUiState();
	const [{ isSchemaGraphOpen }, { closeSchemaGraph }] = useInsightObjectSchemaPageData();

	return (
		<ModalTransition>
			{selectedObjectTypeId && isSchemaGraphOpen && (
				<AsyncAssetsSchemaGraphModal
					schemaId={objectSchemaId}
					onClose={closeSchemaGraph}
					defaultSelectedObjectTypeId={selectedObjectTypeId}
				/>
			)}
		</ModalTransition>
	);
};
