import React from 'react';
import { lazy } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { BulkActionsProps } from './common/types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyBulkActions = lazy(
	() => import(/* webpackChunkName: "insight-bulk-actions" */ './ui/main'),
);

export const AsyncBulkActions = (props: BulkActionsProps) => (
	<Placeholder name="insight-bulk-actions" fallback={null}>
		<LazyBulkActions {...props} />
	</Placeholder>
);
