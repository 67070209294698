import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import {
	getCmdbObjectNavlistQueryLanguageUrl,
	getCmdbObjectNavlistUrl,
} from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/object.tsx';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import { RESULTS_PER_PAGE } from '../../../common/constants';
import type { SearchRequest, SearchScope, TransformedSearchResponse } from '../../../common/types';
import {
	type BaseSearchRequestPayload,
	type FilterSearchRequestPayload,
	type IqlSearchRequestPayload,
	REQUEST_ASCENDING,
	REQUEST_DESCENDING,
} from '../../../common/types/requests';
import type { SearchResponse, SearchObjectTypesResponse } from '../../../common/types/responses';
import { checkIsSupportedSearchScope, transformSearchResponse } from '../../../common/utils';
import { createFilterRequestItems } from '../../../common/utils/filters';
import { getObjectTypesIfObjectIsInherited } from '../../../common/utils/filters/object-type';
import { replaceFilterStatusNamesWithIds } from '../../../common/utils/filters/status/index.tsx';

export const fetchSearchResults = async ({
	workspaceId,
	searchScope,
	searchRequest,
}: {
	workspaceId: WorkspaceId;
	searchScope: SearchScope;
	searchRequest: SearchRequest;
}): Promise<TransformedSearchResponse> => {
	const { objectSchemaId, objectTypeId } = checkIsSupportedSearchScope(searchScope);
	const { query, page } = searchRequest;

	const baseRequestPayload: BaseSearchRequestPayload = {
		objectTypeId,
		objectSchemaId,
		// Pass in null for now to fetch all attributes
		// NB: this ensures that ALL object type attributes (objectTypeAttributes property) are returned so is used to
		// render the list of all possible attributes. If this is changed to a subset (i.e. to only fetch required
		// attribute VALUES), then the list of all attributes should be fetched separately.
		attributesToDisplay: null,
		includeAttributes: true,
		objectId: page.type === 'page-containing-object' ? page.objectId : undefined,
		page: page.type === 'page-number' ? page.pageNumber : 1,
		resultsPerPage: RESULTS_PER_PAGE,
	};

	switch (query.type) {
		case 'qlQuery': {
			const { qlQuery } = query;
			const requestPayload: IqlSearchRequestPayload = {
				...baseRequestPayload,
				qlQuery,
			};
			const response: SearchResponse = await performPostRequest(
				getCmdbObjectNavlistQueryLanguageUrl(workspaceId),
				{ body: JSON.stringify(requestPayload) },
			);

			const searchResultObjectTypes: SearchObjectTypesResponse | null =
				await getObjectTypesIfObjectIsInherited({
					objectTypeIsInherited: response.objectTypeIsInherited,
					workspaceId,
					objectTypeId,
				});
			const searchResult = transformSearchResponse(response, searchResultObjectTypes);
			return {
				...searchResult,
				filters: await replaceFilterStatusNamesWithIds({
					workspaceId,
					schemaId: objectSchemaId,
					filters: searchResult.filters,
				}),
			};
		}
		case 'filter': {
			const { orderBy, filters, objectTypes } = query;
			const requestPayload: FilterSearchRequestPayload = {
				...baseRequestPayload,
				filters: createFilterRequestItems(filters, objectTypes),
			};
			if (orderBy !== null) {
				requestPayload.asc = orderBy.direction === 'ASC' ? REQUEST_ASCENDING : REQUEST_DESCENDING;
				requestPayload.orderByTypeAttrId = orderBy.objectTypeAttributeId;
			}
			const response: SearchResponse = await performPostRequest(
				getCmdbObjectNavlistUrl(workspaceId),
				{ body: JSON.stringify(requestPayload) },
			);
			const searchResultObjectTypes: SearchObjectTypesResponse | null =
				await getObjectTypesIfObjectIsInherited({
					objectTypeIsInherited: response.objectTypeIsInherited,
					workspaceId,
					objectTypeId,
				});

			const searchResult = transformSearchResponse(response, searchResultObjectTypes);
			return {
				...searchResult,
				filters: await replaceFilterStatusNamesWithIds({
					workspaceId,
					schemaId: objectSchemaId,
					filters: searchResult.filters,
				}),
			};
		}
		default:
			// prettier-ignore
			// @ts-expect-error - TS2339 - Property 'type' does not exist on type 'never'.
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(query.type as never);
			throw new Error("Unexpected query type is not 'qlQuery' or 'filter'");
	}
};
