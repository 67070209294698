import React from 'react';
import { isFedRamp } from '@atlassian/atl-context';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { expValEquals, UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { IntercomContextProvider } from '@atlassian/jira-intercom-wrapper/src/controllers/index.tsx';
import { useGetUserEligibility } from '@atlassian/jira-servicedesk-standard-to-premium-modal/src/controllers/user-eligibility';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../common/constants';
import { useIsUserOnPremiumTrial } from '../hooks/user-on-premium-trial';
import { IntercomLoader } from './cmdb-intercom-loader';

const IntercomChat = () => {
	const isSiteAdmin = useIsSiteAdmin();
	const { isUserEligible } = useGetUserEligibility();
	const { isUserOnPremiumTrial } = useIsUserOnPremiumTrial(isUserEligible);
	const isTargetUser = !isFedRamp() && isUserEligible && isSiteAdmin;
	const isTargetUserOnPremiumTrial = !isFedRamp() && isUserOnPremiumTrial && isSiteAdmin;
	if (
		isTargetUser &&
		expValEquals<string>(
			'jsm_premium_upsell_intercom_integration_experiment',
			'cohort',
			'variation',
		)
	) {
		return (
			<IntercomContextProvider>
				<IntercomLoader />
			</IntercomContextProvider>
		);
	}
	if (
		isTargetUserOnPremiumTrial &&
		UNSAFE_noExposureExp('jsm_premium_upsell_intercom_integration_experiment')[0].get(
			'cohort',
			'not-enrolled',
		) !== 'variation' &&
		expValEquals<string>('live_chat_for_assets_in_premium_trial_experiment', 'cohort', 'variation')
	) {
		return (
			<IntercomContextProvider>
				<IntercomLoader isTargetUserOnPremiumTrial />
			</IntercomContextProvider>
		);
	}
	return null;
};

export const IntercomChatWithErrorBoundry = () => (
	<JSErrorBoundary
		id="cmdb-intercom-chat-error-boundary"
		packageName={PACKAGE_NAME}
		fallback="unmount"
		teamName={TEAM_NAME}
	>
		<IntercomChat />
	</JSErrorBoundary>
);
