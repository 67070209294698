// Remove when cleaning up assets_rearch_async_actions
import React from 'react';
import { lazy } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { CmdbBulkEditModalProps } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/cmdb-bulk-edit-modal.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyCmdbBulkEditModal = lazy(() =>
	import(/* webpackChunkName: "cmdb-bulk-edit-modal" */ './ui').then(
		(module) => module.CmdbBulkEditModal,
	),
);

export const AsyncCmdbBulkEditModal = (props: CmdbBulkEditModalProps) => (
	<Placeholder fallback={null} name="lazy-cmdb-bulk-edit-modal">
		<LazyCmdbBulkEditModal {...props} />
	</Placeholder>
);
