import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import { getCmdbRestBaseUrl } from './index';

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/user-history
 */
const getCmdbUserHistoryUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbRestBaseUrl(workspaceId)}/user-history`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/user-history/viewed
 */
export const getCmdbUserHistoryViewedUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbUserHistoryUrl(workspaceId)}/viewed`;
