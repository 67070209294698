import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import type { SearchMode } from '../types';

export const insightObjectSearchLocalStorage = createLocalStorageProvider(
	'jsm-cmdb-insight-object-search',
);
// localStorage sample structure: jsm-cmdb-insight-object-search.object-type:123.selected-columns = [icon, 1, 2, 3]
export const SELECTED_COLUMNS = 'selected-columns';
export const OBJECT_TYPE = 'object-type:';

export const setLocalLastSearchQuery = (objectTypeId: string, query: string) => {
	insightObjectSearchLocalStorage.set(`localLastSearchQuery-${objectTypeId}`, query);
};

export const getLocalLastSearchQuery = (objectTypeId: string) =>
	insightObjectSearchLocalStorage.get(`localLastSearchQuery-${objectTypeId}`);

export const setLocalSearchMode = (objectTypeId: string, mode: SearchMode) => {
	insightObjectSearchLocalStorage.set(`searchMode-${objectTypeId}`, mode);
};

export const getLocalSearchMode = (objectTypeId: string) =>
	insightObjectSearchLocalStorage.get(`searchMode-${objectTypeId}`);
