import React, { useMemo } from 'react';
import { SuccessFlag, type FlagProps } from '@atlassian/jira-flags';
import type { FlagCustom } from '@atlassian/jira-flags/src/services/types';
import { useIntl } from '@atlassian/jira-intl';
import type { CompletedBulkDeleteTask } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task';
import { CompletedFlagDescription } from '../completed-flag-description';
import commonMessages from '../messages';
import type { BaseFlagProps } from '../types';
import messages from './messages';

export type CompletedFlagBulkDeleteProps = {
	task: CompletedBulkDeleteTask;
} & BaseFlagProps;

export const CompletedFlagBulkDelete = ({
	task,
	flagProps,
	onDismissed,
}: CompletedFlagBulkDeleteProps) => {
	const { formatMessage } = useIntl();
	const itemRequestedCount = task.itemRequestedCount ?? 0;
	const flagActions: FlagProps['actions'] = useMemo(
		() => [
			{
				content: formatMessage(commonMessages.completedFlagViewReportNonfinal),
			},
		],
		[formatMessage],
	);
	return (
		<SuccessFlag
			{...flagProps}
			title={formatMessage(messages.flagTitle, {
				itemRequestedCount,
			})}
			id={task.identifier}
			description={
				<CompletedFlagDescription task={task}>
					{!task.hasError
						? formatMessage(commonMessages.completedFlagDescriptionNonfinal)
						: formatMessage(commonMessages.completedFlagErrorDescriptionNonfinal)}
				</CompletedFlagDescription>
			}
			actions={task.hasError ? flagActions : undefined}
			onDismissed={onDismissed}
			testId="servicedesk-cmdb-async-actions.common.ui.flags.completed-flag-bulk-delete"
		/>
	);
};

export const bulkDeleteFlagHandler = (
	props: Omit<CompletedFlagBulkDeleteProps, 'flagProps'>,
): FlagCustom => ({
	id: props.task.identifier,
	render: (flagProps) => <CompletedFlagBulkDelete {...props} flagProps={flagProps} />,
});
