import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { getCmdbObjectTypeByIdUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/object-type.tsx';
import type { ObjectType as ObjectTypeResponse } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/object-type.tsx';
import type {
	CmdbObjectTypeId,
	WorkspaceId,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type { ObjectType } from '../../common/types';
import { transformObjectTypeResponse } from '../../common/utils';

export type RequestProperties = {
	name?: string;
	iconId?: string;
};

export const performUpdateObjectType = async ({
	workspaceId,
	objectTypeId,
	updatedProperties,
}: {
	workspaceId: WorkspaceId;
	objectTypeId: CmdbObjectTypeId;
	updatedProperties: RequestProperties;
}): Promise<ObjectType> => {
	const updatedObjectTypeResponse: ObjectTypeResponse = await performPutRequest(
		getCmdbObjectTypeByIdUrl(workspaceId, objectTypeId),
		{
			body: JSON.stringify(updatedProperties),
		},
	);
	return transformObjectTypeResponse(updatedObjectTypeResponse, 'OBJECT_TYPE_MANAGER');
};
