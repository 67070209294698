import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import { fg } from '@atlassian/jira-feature-gating';
import { JsmPageInteractiveEvent } from '@atlassian/jira-servicedesk-common/src/utils/browser-metric/index.tsx';
import {
	useLoadedSchemaPageData,
	useSchemaPageUiState,
} from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services';
import {
	canAccessAttributes,
	isObjectTypeInherited,
	isParentObjectTypeInherited,
	hasParentObjectType,
} from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services/selectors/index.tsx';
import { useObjectSearchStoreState } from '@atlassian/jira-servicedesk-insight-object-search-store/src/controllers/store/index.tsx';
import { objectTypeHasNoObjects } from '@atlassian/jira-servicedesk-insight-object-search-store/src/services/selectors/index.tsx';
import { assertUnreachable } from '@atlassian/jira-servicedesk-insight-shared-utils/src/common/utils/assert-unreachable/index.tsx';
import { Redirect, useRouter } from '@atlassian/react-resource-router';
import { AttributesView } from './attributes';
import { NoObjects } from './no-objects';
import { ObjectDetails } from './object-details';
import { ObjectList } from './object-list';
import { ObjectView } from './object-view';

export const ObjectViewContent = () => {
	const [{ objectViewMode, objectId }] = useSchemaPageUiState();
	const [{ lastSearch }, { refreshSearch }] = useObjectSearchStoreState();

	// Refresh the search whenever loading this component (i.e. to ensure data is up to date after switching tabs)
	useEffect(() => {
		refreshSearch();
	}, [refreshSearch]);

	if (objectTypeHasNoObjects(lastSearch)) {
		return (
			<>
				<JsmPageInteractiveEvent extra={{ schemaViewMode: 'object', eventMonitorMode: 'detail' }} />
				<NoObjects />
			</>
		);
	}
	switch (objectViewMode) {
		case 'details': {
			return <ObjectDetails />;
		}
		case 'list':
			if (objectId) {
				return (
					<>
						<ObjectView
							objectId={objectId}
							data-testid="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-content.object-view"
						/>
						<JsmPageInteractiveEvent
							extra={{ schemaViewMode: 'object', eventMonitorMode: 'object' }}
						/>
					</>
				);
			}
			return (
				<ObjectList data-testid="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-content.object-list" />
			);
		default:
			assertUnreachable(objectViewMode);
			return null;
	}
};

export const PageContent = () => {
	const [schemaPageData] = useLoadedSchemaPageData();
	const [{ schemaViewMode, objectSchemaId, selectedObjectTypeId }] = useSchemaPageUiState();
	const [{ location }] = useRouter();
	const canShowAttributes =
		selectedObjectTypeId !== null && canAccessAttributes(schemaPageData, selectedObjectTypeId);
	const hasInheritedParentAttributes =
		selectedObjectTypeId !== null &&
		isParentObjectTypeInherited(schemaPageData, selectedObjectTypeId);
	const isInherited =
		selectedObjectTypeId !== null && isObjectTypeInherited(schemaPageData, selectedObjectTypeId);

	const hasParent =
		selectedObjectTypeId !== null && hasParentObjectType(schemaPageData, selectedObjectTypeId);
	switch (schemaViewMode) {
		case 'object': {
			return <ObjectViewContent />;
		}
		case 'attribute': {
			if (!canShowAttributes) {
				const redirectUrl = `${location.pathname}/${location.search.replace(
					'attribute',
					'object',
				)}`;
				return <Redirect to={redirectUrl} />;
			}

			const { objectTypesById, schemaGlobalConfig } = schemaPageData;
			return (
				<AttribtuesConfigWrapper data-testid="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-content.attributes-config-wrapper">
					<JsmPageInteractiveEvent
						extra={{ schemaViewMode: 'attribute', eventMonitorMode: 'attribute' }}
					/>
					<AttributesView
						schemaId={objectSchemaId}
						objectTypeId={selectedObjectTypeId}
						objectTypeName={
							fg('jsm-cmdb-enforce-attribute-limits')
								? objectTypesById[selectedObjectTypeId].name
								: ''
						}
						hasInheritedParentAttributes={hasInheritedParentAttributes}
						isObjectTypeInherited={isInherited}
						hasParent={hasParent}
						attributesPerObjectTypeLimit={
							fg('jsm-cmdb-enforce-attribute-limits')
								? schemaGlobalConfig.limitInfo.attributesPerObjectTypeLimit
								: 100
						}
						attributeCardinalityLimit={
							fg('jsm-cmdb-enforce-attribute-limits')
								? schemaGlobalConfig.limitInfo.attributeCardinalityLimit
								: 1000
						}
					/>
				</AttribtuesConfigWrapper>
			);
		}

		default:
			assertUnreachable(schemaViewMode);
			return null;
	}
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AttribtuesConfigWrapper = styled.div({
	overflowY: 'auto',
	minHeight: 100, // prevents the spinner causing the scrollbars to appear
});
