import type { TaskOperationType } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task';
import messages from '../../../common/messages';
import type { CmdbAsyncActionStoreAction } from '../../types';
import { mapActionToErrorMessage } from '../common/utils/map-action-to-message';

type Props = {
	objectCount: string;
	action: TaskOperationType;
};

export const showErrorFlag =
	({ objectCount, action }: Props): CmdbAsyncActionStoreAction =>
	async (_, { showFlag, formatMessage }) => {
		showFlag({
			type: 'error',
			title: formatMessage(mapActionToErrorMessage(action), {
				objectCount,
			}),
			description: formatMessage(messages.submitErrorFlagDescription),
		});
	};
