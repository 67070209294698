// Remove when cleaning up assets_rearch_async_actions
import uuid from 'uuid';
import type { CmdbBulkEditModalData } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/cmdb-bulk-edit-modal.tsx';
import type { ActionApi } from '../../common/types';

type OpenBulkEditModalAction = Omit<CmdbBulkEditModalData, 'uuid'>;

export const openBulkEditModal =
	({ objectTypeId, qlQuery, numObjects, isCapped }: OpenBulkEditModalAction): ActionApi =>
	async ({ getState, setState }) => {
		// Currently we want to generate a new UUID everytime the bulk edit modal is opened
		const uniqueId = uuid.v4();
		const { bulkEditModalsState } = getState();
		setState({
			bulkEditModalsState: [
				...bulkEditModalsState,
				{ objectTypeId, qlQuery, numObjects, isCapped, uuid: uniqueId },
			],
		});
	};

export const closeBulkEditModal =
	(closedUUID: string): ActionApi =>
	async ({ getState, setState }) => {
		const { bulkEditModalsState } = getState();
		const updatedBulkEditModalState = bulkEditModalsState.filter(
			(bulkEditModalState) => bulkEditModalState.uuid !== closedUUID,
		);
		setState({
			bulkEditModalsState: updatedBulkEditModalState,
		});
	};
