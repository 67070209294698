/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { styled, css, jsx } from '@compiled/react';
import { ErrorMessage } from '@atlaskit/form';
import InlineEdit from '@atlaskit/inline-edit';
import { Box, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useFlagService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useLoadedSchemaPageData } from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services/index.tsx';
import { canUpdateObjectType } from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services/selectors/index.tsx';
import type { CmdbObjectTypeId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import { commonMessages } from '../../../../../../common/messages';
import { messages } from './messages';

const ReadView = ({ name }: { name: string }) => (
	<Name data-testid="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-header.editable-object-type.editable-name.read-view">
		{name}
	</Name>
);

const MIN_NAME_LENGTH = 2;
const MAX_NAME_LENGTH = 50;

export const EditableName = ({ objectTypeId }: { objectTypeId: CmdbObjectTypeId }) => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();
	const [schemaPageData, { updateObjectType }] = useLoadedSchemaPageData();
	const objectType = schemaPageData.objectTypesById[objectTypeId];

	const [name, setName] = useState(objectType.name);
	const [validationError, setValidationError] = useState<string | null>(null);

	// Sync externally-changed object type name into name state
	useEffect(() => {
		setName(objectType.name);
	}, [objectType.name]);

	if (!canUpdateObjectType(schemaPageData, objectTypeId)) {
		return <ReadView name={name} />;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onConfirm = async (newName: any) => {
		setName(newName);
		setValidationError(null);
		try {
			await updateObjectType({ objectTypeId, updatedProperties: { name: newName } });
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (error instanceof ValidationError) {
				setValidationError(error.errors.map((message) => message.error).join(', '));
			} else {
				showFlag({
					type: 'error',
					title: commonMessages.updateObjectTypeErrorTitle,
					description: commonMessages.updateObjectTypeErrorDescription,
					isAutoDismiss: true,
				});
			}
		}
	};
	const validateInput = (value: string) => {
		const trimmed = value.trim();
		if (trimmed.length < MIN_NAME_LENGTH) {
			return formatMessage(messages.minValidation, { min: MIN_NAME_LENGTH });
		}
		if (trimmed.length > MAX_NAME_LENGTH) {
			return formatMessage(messages.maxValidation, { max: MAX_NAME_LENGTH });
		}
		return undefined;
	};

	return (
		<Box xcss={inlineEditContainerStyles}>
			<InlineEdit
				// Force this component to re-init when switching object-types
				// otherwise it remembers the previously edited value
				key={objectTypeId}
				readView={() => <ReadView name={name} />}
				editView={({ errorMessage, ...fieldProps }) => (
					<Box xcss={editViewSpacingOffsetStyles}>
						<Textfield
							{...fieldProps}
							isCompact
							autoFocus
							css={textFieldStyles}
							testId="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-header.editable-object-type.editable-name.edit-view"
						/>
						{errorMessage != null && <ErrorMessage>{errorMessage}</ErrorMessage>}
					</Box>
				)}
				defaultValue={name}
				onConfirm={(newName) => {
					onConfirm(newName);
				}}
				onCancel={() => {
					setName(objectType.name);
					setValidationError(null);
				}}
				validate={validateInput}
				// Verbose aria-label for a11y focus hack - for cmdb-object-schema-quick-search '[aria-label="Edit Object Type Name"]'
				// This is the previous element for cmdb-object-schema-quick-search, this label is used to manually move focus backwards to the correct previous element
				editButtonLabel="Edit Object Type Name"
			/>
			{validationError !== null && <ErrorMessage>{validationError}</ErrorMessage>}
		</Box>
	);
};

const textFieldStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > [data-ds--text-field--input]': {
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
		fontSize: '24px',
	},
});

const inlineEditContainerStyles = xcss({
	/* Cancel out unwanted top margin provided by inline edit */
	marginTop: 'space.negative.100',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Name = styled.div({
	margin: 0,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '24px',
	wordBreak: 'break-all',
	width: '100%',
});

const editViewSpacingOffsetStyles = xcss({
	marginLeft: 'space.negative.075',
	marginTop: 'space.negative.100',
	marginBottom: 'space.negative.100',
});
