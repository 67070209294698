/** @jsx jsx */
import React, { useRef, useState, type ReactNode, type CSSProperties } from 'react';
import { css, jsx } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { LimitsBanner } from '@atlassian/jira-servicedesk-cmdb-object-usage-messaging/src/ui/limits-banner/index.tsx';
import { PanelSplitter, PanelSplitterProvider } from '@atlassian/navigation-system';
import messages from './messages';
import { TopHeaderWithBreadcrumbs, SCHEMA_NAME_HEADER_OFFSET_TOP } from './page-header';

type ObjectSchemaPageLayoutProps = {
	leftComponent: ReactNode;
	rightComponent: ReactNode;
};
const LEFT_COMPONENT_WIDTH = 320;
const PAGE_LAYOUT_OFFSET_TOP = 'var(--topNavigationHeight, 0px) + var(--bannerHeight, 0px)';
const resizingCssVar = '--panel-splitter-resizing';
const widthVar = '--panel-width';
const ASSETS_SCHEMA_TREE_SIDEBAR = 'assets-schema-tree-sidebar';
const SIDEBAR_WIDTH = 'sidebar-width';
const MIN_WIDTH = 240;
const MAX_WIDTH = 870;
export const ObjectSchemaPageLayout = ({
	leftComponent,
	rightComponent,
}: ObjectSchemaPageLayoutProps) => {
	const panelSplitterParentRef = useRef<HTMLDivElement | null>(null);
	const localStorage = createLocalStorageProvider(ASSETS_SCHEMA_TREE_SIDEBAR);
	const initialWidth = localStorage.get(SIDEBAR_WIDTH);
	const [width, setWidth] = useState<number>(
		initialWidth === undefined
			? LEFT_COMPONENT_WIDTH
			: Math.min(MAX_WIDTH, Math.max(MIN_WIDTH, initialWidth)),
	);
	const { formatMessage } = useIntl();
	const setAndStoreWidth = (newWidth: number) => {
		const validNewWidth = Math.min(MAX_WIDTH, Math.max(MIN_WIDTH, newWidth));
		setWidth(validNewWidth);
		localStorage.set(SIDEBAR_WIDTH, validNewWidth);
	};

	return (
		<Box>
			{fg('jsm_assets_cbp_limits_and_notifications') ? <LimitsBanner displayWithOffset /> : null}
			<TopHeaderWithBreadcrumbs />
			<Box xcss={dividerStyles} />
			<Box xcss={layoutStyles}>
				<div
					ref={panelSplitterParentRef}
					css={resizableStyles}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, jira/react/no-style-attribute -- Ignored via go/DSP-18766
					style={
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						{
							[widthVar]: `${width}px`,
						} as CSSProperties
					}
					data-testId="servicedesk-insight-object-schema-page.common.ui.object-schema-page-layout.left-navigation"
				>
					<PanelSplitterProvider
						panelRef={panelSplitterParentRef}
						panelWidth={width}
						onCompleteResize={setAndStoreWidth}
						resizeBounds={{ min: `${MIN_WIDTH}px`, max: `${MAX_WIDTH}px` }}
						resizingCssVar={resizingCssVar}
					>
						{leftComponent}
						<PanelSplitter label={formatMessage(messages.a11YSidebarDividerDescription)} />
					</PanelSplitterProvider>
				</div>
				<Box xcss={rightolumnStyle}>{rightComponent}</Box>
			</Box>
		</Box>
	);
};

const rightolumnStyle = xcss({
	width: '100%',
	paddingLeft: 'space.500',
});

// Discussed with design system, they recomend using emotion css in js to support the resizable feature
const resizableStyles = css({
	position: 'relative',
	backgroundColor: token('elevation.surface'),
	width: `var(${resizingCssVar}, var(${widthVar}))`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	height: `calc(100vh - ${PAGE_LAYOUT_OFFSET_TOP} - ${SCHEMA_NAME_HEADER_OFFSET_TOP}px)`,
	borderInlineEndWidth: token('border.width'),
	marginLeft: token('space.negative.400'),
	borderInlineEndStyle: 'solid',
	borderInlineEndColor: token('color.border'),
	overflow: 'visible',
});

const dividerStyles = xcss({
	border: 0.5,
	zIndex: 'navigation',
	borderStyle: 'solid',
	borderColor: 'color.border',
	position: 'absolute',
	// not tokenize due to no space.negative.500, maximum 400.
	right: '-40px',
	left: '-40px',
});

const layoutStyles = xcss({
	display: 'grid',
	gridTemplateColumns: 'auto minmax(0, 1fr)',
});
