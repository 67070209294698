import React, { type MouseEvent, useState, useCallback, useEffect, useMemo } from 'react';
import { styled } from '@compiled/react';
import throttle from 'lodash/throttle';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { DropdownItem as MeatballsMenuItem } from '@atlaskit/dropdown-menu';
import CopyIcon from '@atlaskit/icon/glyph/copy';
import DownloadIcon from '@atlaskit/icon/glyph/download';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { token } from '@atlaskit/tokens';
import { WidthObserver } from '@atlaskit/width-detector';
import { useIntl } from '@atlassian/jira-intl';
import { AsyncCmdbObjectSchemaQuickSearch } from '@atlassian/jira-servicedesk-cmdb-object-schema-quick-search/src/async.tsx';
import { useWorkspaceContext } from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/controllers/workspace-context/index.tsx';
import { AsyncImportHistoryModal } from '@atlassian/jira-servicedesk-insight-object-import-log-modal/src/async';
import type { ImportHistoryData } from '@atlassian/jira-servicedesk-insight-object-import-log-modal/src/common/types.tsx';
import { fetchImportResults } from '@atlassian/jira-servicedesk-insight-object-import-log-modal/src/services/fetch-import-history/index.tsx';
import {
	useLoadedSchemaPageData,
	useSchemaPageUiState,
} from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services/index.tsx';
import {
	canCreateObject,
	canCloneObjectType,
	canViewObjectTypeSettings,
} from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services/selectors/index.tsx';
import { MeatballsMenu } from '@atlassian/jira-servicedesk-insight-shared-ui/src/ui/meatballs-menu/main.tsx';
import {
	createObjectTypeSettingsUrl,
	getCreateServiceUrl,
} from '@atlassian/jira-servicedesk-insight-urls';
import { useRouterActions } from '@atlassian/react-resource-router';
import { EditableObjectType } from './editable-object-type';
import { messages } from './messages';

const NARROW_WIDTH_BREAKPOINT = 550;

export const PageHeader = () => {
	const { formatMessage } = useIntl();
	const { push } = useRouterActions();
	const [{ selectedObjectTypeId }] = useSchemaPageUiState();
	const [schemaPageData, { setObjectTypeModalState, openCreateObject }] = useLoadedSchemaPageData();

	const [width, setWidth] = useState(0);
	const throttledSetWidth = useMemo(() => throttle(setWidth, 250), []);
	const [isImportModalOpen, setIsImportModalOpen] = useState(false);
	const openModal = useCallback(() => {
		setIsImportModalOpen(true);
	}, []);
	const closeModal = useCallback(() => {
		setIsImportModalOpen(false);
	}, []);

	const { workspaceId } = useWorkspaceContext();

	const [importHistoryData, setImportHistoryData] = useState<ImportHistoryData>({
		inFlight: [],
		finished: [],
	});

	useEffect(() => {
		fetchImportResults(workspaceId, selectedObjectTypeId).then((imports) => {
			setImportHistoryData(imports);
		});
	}, [workspaceId, selectedObjectTypeId]);

	const showImportLogMeatballItem =
		importHistoryData.inFlight.length > 0 || importHistoryData.finished.length > 0;

	// This may occur if there are no object types or the selected one has become invalid
	if (selectedObjectTypeId === null) {
		return null;
	}

	const { name } = schemaPageData.objectTypesById[selectedObjectTypeId];

	const canViewSettings = canViewObjectTypeSettings(schemaPageData, selectedObjectTypeId);
	const canClone = canCloneObjectType(schemaPageData, selectedObjectTypeId);
	const hasAnyActions = canViewSettings || canClone || showImportLogMeatballItem;
	const toShowCreateObjectButton =
		schemaPageData.objectSchema.isRestricted ||
		canCreateObject(schemaPageData, selectedObjectTypeId);

	return (
		<WidthObserverParent>
			<WidthObserver setWidth={throttledSetWidth} />
			<Container isNarrow={width > 0 && width < NARROW_WIDTH_BREAKPOINT}>
				<EditableObjectType objectTypeId={selectedObjectTypeId} />
				<Actions>
					{workspaceId && (
						<AsyncCmdbObjectSchemaQuickSearch
							workspaceId={workspaceId}
							objectSchema={schemaPageData.objectSchema}
							objectTypesById={schemaPageData.objectTypesById}
							getPrevElementForA11yFocus={() => {
								// This will select the previous component - EditableObjectType
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, jira/jira-ssr/no-unchecked-globals-usage
								const editableObjectTypeElement = document.querySelector(
									'[aria-label="Edit Object Type Name"]',
								) as HTMLButtonElement | null;
								return editableObjectTypeElement;
							}}
							getNextElementForA11yFocus={() => {
								if (toShowCreateObjectButton) {
									// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
									const createObjectButton = document.getElementById(
										'servicedesk-insight-object-schema-page.ui.page-content.loaded.page-header.create',
									);
									return createObjectButton;
								}
								if (hasAnyActions) {
									// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, jira/jira-ssr/no-unchecked-globals-usage
									const meatballMenuElement = document.getElementById(
										'servicedesk-insight-object-schema-page.ui.page-content.loaded.page-header.meatballs-menu',
									) as HTMLButtonElement;
									return meatballMenuElement;
								}

								// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
								const tabsElement = document.getElementById('insight-object-schema-page-tabs-0');
								return tabsElement;
							}}
						/>
					)}

					{toShowCreateObjectButton ? (
						<Button
							id="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-header.create"
							testId="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-header.create"
							interactionName="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-header.create"
							appearance="primary"
							onClick={(event: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
								if (!schemaPageData.objectSchema.isRestricted) {
									openCreateObject(analyticsEvent);
								} else {
									push(getCreateServiceUrl());
								}
							}}
						>
							{formatMessage(messages.createObject)}
						</Button>
					) : null}

					{hasAnyActions ? (
						<MeatballsMenu
							moreIconLabel={formatMessage(messages.more)}
							tabIndex={0}
							id="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-header.meatballs-menu"
							testId="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-header.meatballs-menu"
						>
							{canViewSettings && (
								<MeatballsMenuItem
									testId="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-header.settings"
									href={createObjectTypeSettingsUrl(selectedObjectTypeId)}
									elemBefore={
										<SettingsIcon label={formatMessage(messages.objectTypeConfiguration)} />
									}
									onClick={(event) => {
										push(createObjectTypeSettingsUrl(selectedObjectTypeId));
										event.preventDefault();
									}}
								>
									{formatMessage(messages.objectTypeConfiguration)}
								</MeatballsMenuItem>
							)}
							{canClone && (
								<MeatballsMenuItem
									testId="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-header.clone"
									elemBefore={<CopyIcon label={formatMessage(messages.copyObjectType)} />}
									onClick={() =>
										setObjectTypeModalState({
											type: 'copying',
											objectTypeIdToCopy: selectedObjectTypeId,
										})
									}
								>
									{formatMessage(messages.copyObjectType)}
								</MeatballsMenuItem>
							)}
							{showImportLogMeatballItem ? (
								<MeatballsMenuItem
									elemBefore={<DownloadIcon label={formatMessage(messages.viewImportLogs)} />}
									onClick={openModal}
								>
									{formatMessage(messages.viewImportLogs)}
								</MeatballsMenuItem>
							) : null}
						</MeatballsMenu>
					) : null}
				</Actions>
			</Container>
			<ModalTransition>
				{isImportModalOpen && (
					<AsyncImportHistoryModal
						objectTypeName={name}
						data={importHistoryData}
						onClose={closeModal}
					/>
				)}
			</ModalTransition>
		</WidthObserverParent>
	);
};

// Required by AK WidthObserver
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WidthObserverParent = styled.div({
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ isNarrow: boolean }>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	flexDirection: (props) => (props.isNarrow ? 'column' : 'row'),
	justifyContent: 'space-between',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	alignItems: (props) => (props.isNarrow ? 'stretch' : 'center'),
	padding: `${token('space.300', '24px')} 0 ${token('space.100', '8px')}`,
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Actions = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const visibleForTesting = {
	Container,
	Actions,
};
