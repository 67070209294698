import React from 'react';
import RefreshIcon from '@atlaskit/icon/glyph/refresh';
import type { TaskOperationType } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task';
import messages from '../../../common/messages';
import type { CmdbAsyncActionStoreAction } from '../../types';
import { mapActionToSuccessMessage } from '../common/utils/map-action-to-message';

type Props = {
	id: string;
	objectCount: string;
	action: TaskOperationType;
};

export const showSuccessFlag =
	({ id, objectCount, action }: Props): CmdbAsyncActionStoreAction =>
	async (_, { showFlag, formatMessage }) => {
		showFlag({
			id,
			icon: <RefreshIcon label="" />,
			type: 'info',
			title: formatMessage(mapActionToSuccessMessage(action), {
				objectCount,
			}),
			description: formatMessage(messages.submitSuccessFlagDescription),
		});
	};
