import type { CmdbObjectTypeId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type { ObjectTypesById, State, SuccessState } from '../../common/types';
import { getOrderedChildObjectTypes } from '../selectors';

export const getPageDataOrThrow = (state: Readonly<State>): SuccessState => {
	const { pageDataState } = state;
	if (pageDataState.type !== 'success') {
		throw new Error(`Unexpected pageDataState.type value: ${pageDataState.type}`);
	} else {
		return pageDataState;
	}
};

export type SelectionValidation =
	| {
			type: 'valid';
	  }
	| {
			type: 'invalid';
			newSelectedId: CmdbObjectTypeId | null;
	  };

/**
 * Validates that the given object type ID is a valid selection, and if not provides one to redirect to.
 */
export const validateSelectedObjectTypeId = (
	selectedObjectTypeId: CmdbObjectTypeId | null,
	objectTypesById: ObjectTypesById,
): SelectionValidation => {
	if (selectedObjectTypeId !== null && objectTypesById[selectedObjectTypeId]) {
		return { type: 'valid' };
	}
	if (selectedObjectTypeId === null && Object.keys(objectTypesById).length === 0) {
		return { type: 'valid' };
	}

	const orderedRootObjectTypes = getOrderedChildObjectTypes({
		parentObjectTypeId: null,
		objectTypesById,
	});

	const [firstObjectTypeOrNull = null] = orderedRootObjectTypes;
	return {
		type: 'invalid',
		newSelectedId: firstObjectTypeOrNull !== null ? firstObjectTypeOrNull.id : null,
	};
};
