import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { fireJsonOnlyErrorAnalytics } from '@atlassian/jira-servicedesk-common/src/utils/analytics';
import {
	OPERATION_TYPE_BULK_EDIT,
	STATUS_QUEUED,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task';
import { mergeTasks } from '../../../common/utils/merge-tasks';
import { createAsyncBulkEditTask } from '../../services/create-async-bulk-edit-task';
import type { AsyncBulkEditParams } from '../../services/create-async-bulk-edit-task/types';
import type { CmdbAsyncActionStoreAction } from '../../types';
import { showErrorFlag } from '../show-error-flag';
import { showSuccessFlag } from '../show-success-flag';
import { startTaskPolling } from '../start-task-polling';

export type SubmitBulkEditTaskProps = {
	objectCount: string;
} & AsyncBulkEditParams;

export const submitBulkEditTask =
	({ objectCount, ...body }: SubmitBulkEditTaskProps): CmdbAsyncActionStoreAction<Promise<void>> =>
	async ({ setState, getState, dispatch }, { workspaceId, createAnalyticsEvent }) => {
		if (getState().bulkEdit.isSubmitting) {
			return;
		}
		setState({ bulkEdit: { isSubmitting: true } });

		try {
			const { taskId } = await createAsyncBulkEditTask(workspaceId, { ...body });

			setState({
				tasks: mergeTasks(getState().tasks, [
					{
						identifier: taskId,
						operationType: OPERATION_TYPE_BULK_EDIT,
						status: STATUS_QUEUED,
					},
				]),
				bulkEdit: { isSubmitting: false },
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'submitAsyncBulkEditTask succeeded');
			dispatch(
				showSuccessFlag({
					id: taskId,
					objectCount,
					action: OPERATION_TYPE_BULK_EDIT,
				}),
			);
			dispatch(startTaskPolling());
		} catch (error) {
			fireJsonOnlyErrorAnalytics({
				error,
				meta: {
					id: 'submitAsyncBulkEditTask',
					packageName: 'jiraServicedeskCmdbAsyncActions',
					teamName: 'falcons',
				},
			});

			setState({ bulkEdit: { isSubmitting: false } });

			dispatch(showErrorFlag({ action: OPERATION_TYPE_BULK_EDIT, objectCount }));

			throw error;
		}
	};
