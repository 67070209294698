import type { FlagComponentDefaultProps } from '@atlassian/jira-flags/src/services/types';
import { getCmdbTaskByIdUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/task';
import {
	OPERATION_TYPE_BULK_DELETE,
	OPERATION_TYPE_BULK_EDIT,
	OPERATION_TYPE_BULK_EXPORT,
	OPERATION_TYPE_BULK_QR_CODE,
	type CompletedTask,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task';
import { bulkDeleteFlagHandler } from '../../../common/ui/flags/completed-flag-bulk-delete';
import { bulkEditFlagHandler } from '../../../common/ui/flags/completed-flag-bulk-edit';
import { bulkExportFlagHandler } from '../../../common/ui/flags/completed-flag-bulk-export';
import { qrCodeFlagHandler } from '../../../common/ui/flags/completed-flag-qr-code';
import type { CmdbAsyncActionStoreAction } from '../../types';
import { dismissTask } from '../dismiss-task';

const throwBadCompletedTask = (p: CompletedTask) => {
	throw new Error(`Unknown operation type: ${p.operationType}`);
};

export const getCompletedFlagFromTask = ({
	task,
	taskUrl,
	onDismissed,
}: {
	task: CompletedTask;
	taskUrl: string;
	onDismissed: FlagComponentDefaultProps['onDismissed'];
}) => {
	switch (task.operationType) {
		case OPERATION_TYPE_BULK_EXPORT:
			return bulkExportFlagHandler({ task, taskUrl, onDismissed });
		case OPERATION_TYPE_BULK_QR_CODE:
			return qrCodeFlagHandler({ task, taskUrl, onDismissed });
		case OPERATION_TYPE_BULK_EDIT:
			return bulkEditFlagHandler({ task, taskUrl, onDismissed });
		case OPERATION_TYPE_BULK_DELETE:
			return bulkDeleteFlagHandler({ task, taskUrl, onDismissed });
		default:
			return throwBadCompletedTask(task);
	}
};

export const showCompletedFlags =
	(completedTasks: CompletedTask[]): CmdbAsyncActionStoreAction<void> =>
	({ dispatch }, { workspaceId, showFlag, dismissFlag }) => {
		completedTasks.forEach((task) => {
			// Dismiss "Action started" flag if still present
			dismissFlag(task.identifier);
			// Pass the dismiss action to the flag component
			const onDismissed = () => {
				// Dismiss the flag from the UI
				dismissFlag(task.identifier);
				// Dismiss the task on the server
				dispatch(dismissTask({ taskId: task.identifier }));
			};
			const taskUrl = getCmdbTaskByIdUrl(workspaceId, task.identifier);
			showFlag(getCompletedFlagFromTask({ task, taskUrl, onDismissed }));
		});
	};
