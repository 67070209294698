import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { getCmdbConfigStatusTypeUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/config.tsx';
// eslint-disable-next-line import/order
import type {
	WorkspaceId,
	SchemaId,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';

import type { CmdbNumericStatusCategory } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/status.tsx';

export type StatusTypeResponse = {
	id: string;
	name: string;
	category: CmdbNumericStatusCategory;
	objectSchemaId?: SchemaId;
	description?: string;
};

export const fetchSchemaStatusTypes = async ({
	workspaceId,
	schemaId,
	controller,
}: {
	workspaceId: WorkspaceId;
	schemaId: SchemaId;
	controller?: AbortController;
}): Promise<StatusTypeResponse[]> => {
	const globalStatusesPromise: Promise<StatusTypeResponse[]> = performGetRequest(
		getCmdbConfigStatusTypeUrl(workspaceId),
		controller ? { signal: controller.signal } : undefined,
	);
	const schemaStatusesPromise: Promise<StatusTypeResponse[]> = performGetRequest(
		getCmdbConfigStatusTypeUrl(workspaceId, {
			objectSchemaId: schemaId,
		}),
		controller ? { signal: controller.signal } : undefined,
	);
	return [...(await globalStatusesPromise), ...(await schemaStatusesPromise)];
};
