import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import ChevronLeftIcon from '@atlaskit/icon/glyph/chevron-left';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line import/order
import Tooltip from '@atlaskit/tooltip';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useObjectSearchPagination } from '@atlassian/jira-servicedesk-insight-object-search-store/src/controllers/store/index.tsx';
import { commonMessages } from '../../../../../../common/messages';
import { ObjectsPaginationLabel } from '../../../../../../common/ui/objects-pagination-label';
import { TotalObjectsPagination } from '../../../../../../common/ui/total-objects-pagination';
import { messages } from './messages';

export const Pagination = () => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();

	const [{ hasNextPage, hasPrevPage, pageNumber, hasMoreResults }, { changePagination }] =
		useObjectSearchPagination();

	const prevButtonMessage = formatMessage(messages.previous);
	const nextButtonMessage = formatMessage(messages.next);
	const nextButtonDisabledMessage = formatMessage(commonMessages.disabledPaginationNextButton);

	const onChangePage = useCallback(
		async (page: number) => {
			try {
				await changePagination(page);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				showFlag({
					type: 'error',
					title: messages.paginationErrorTitle,
					description: messages.paginationErrorDescription,
					isAutoDismiss: true,
				});
			}
		},
		[changePagination, showFlag],
	);

	const renderNextButton = useCallback(() => {
		if (hasNextPage) {
			return (
				<Tooltip content={nextButtonMessage}>
					<Button
						appearance="subtle"
						iconBefore={<ChevronRightIcon label={nextButtonMessage} />}
						onClick={() => onChangePage(pageNumber + 1)}
						testId="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-content.object-details.pagination.next-button"
					/>
				</Tooltip>
			);
		}
		if (!hasNextPage && hasMoreResults && fg('assets_rearch_total_count_deprecated_for_ui')) {
			return (
				<Tooltip content={nextButtonDisabledMessage}>
					<Button
						isDisabled
						appearance="subtle"
						iconBefore={<ChevronRightIcon label={nextButtonDisabledMessage} />}
						testId="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-content.object-details.pagination.next-button-disabled"
					/>
				</Tooltip>
			);
		}
		return <ButtonPlaceholder />;
	}, [
		hasMoreResults,
		hasNextPage,
		nextButtonDisabledMessage,
		nextButtonMessage,
		onChangePage,
		pageNumber,
	]);

	return (
		<PaginationContainer>
			{hasPrevPage ? (
				<Tooltip content={prevButtonMessage}>
					<Button
						appearance="subtle"
						iconBefore={<ChevronLeftIcon label={prevButtonMessage} />}
						onClick={() => onChangePage(pageNumber - 1)}
						testId="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-content.object-details.pagination.prev-button"
					/>
				</Tooltip>
			) : (
				<ButtonPlaceholder />
			)}
			<PaginationLabel>
				{fg('assets_rearch_total_count_deprecated_for_ui') ? (
					<TotalObjectsPagination type="simplified" />
				) : (
					<ObjectsPaginationLabel type="simplified" />
				)}
			</PaginationLabel>
			{renderNextButton()}
		</PaginationContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PaginationContainer = styled.div({
	alignItems: 'center',
	display: 'flex',
	gap: token('space.100', '8px'),
	justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PaginationLabel = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N400),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	fontWeight: 600,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonPlaceholder = styled.span({
	display: 'block',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 4}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 4}px`,
});
