import React, { useEffect } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
// eslint-disable-next-line import/order
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import errorIllustration from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/error/assets/error.svg';
import { useIntl } from '@atlassian/jira-intl';
import { updateObjectFilterExperience } from '@atlassian/jira-servicedesk-insight-experiences';
import { useObjectSearchStoreState } from '@atlassian/jira-servicedesk-insight-object-search-store/src/controllers/store/index.tsx';
import { messages } from './messages';

type ImageWidth = 'medium' | 'large';

const NoResultsIcon = ({ imageWidth }: { imageWidth: ImageWidth }) => {
	const { formatMessage } = useIntl();

	return (
		<Image
			src={errorIllustration}
			alt={formatMessage(messages.noObjectsToDisplay)}
			imageWidth={imageWidth}
		/>
	);
};

export const NoFilterResults = ({
	imageWidth,
	showText,
}: {
	imageWidth: ImageWidth;
	showText: boolean;
}) => {
	const { formatMessage } = useIntl();
	const [searchStoreState, { triggerSuccessfulObjectFilterExperience }] =
		useObjectSearchStoreState();
	const { filtersUpdated } = searchStoreState;

	useEffect(() => {
		if (filtersUpdated) {
			updateObjectFilterExperience.mark('no-filter-results-rendered');
			triggerSuccessfulObjectFilterExperience();
		}
	}, [filtersUpdated, triggerSuccessfulObjectFilterExperience]);
	return (
		<Container>
			<NoResultsIcon imageWidth={imageWidth} />
			{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
			{showText && <span>{formatMessage(messages.noObjectsToDisplay)}</span>}
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const Image = styled.img<{ imageWidth: ImageWidth }>(({ imageWidth }) => ({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	maxWidth: `${imageWidth === 'large' ? 26 * gridSize : 20 * gridSize}px`,
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled2.div({
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.300', '24px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	margin: `${7 * gridSize}px ${token('space.200', '16px')} 0`,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '16px',
	alignItems: 'center',
	textAlign: 'center',
});
