import { useEffect, useState } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { SERVICE_DESK } from '@atlassian/jira-shared-types/src/application-key.tsx';
import { PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useProductEntitlementDetails } from '@atlassian/jira-tenant-context-controller/src/components/product-entitlement-details/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants';
import { JSM_PRODUCT_KEY, fetchHamsEntitlementDetails } from './utils';

export const useIsUserOnPremiumTrial = (isUserEligibleOnStandardPaid: boolean) => {
	const [isUserOnPremiumTrial, setIsUserOnPremiumTrial] = useState<boolean>(false);
	const productEntitlementDetails = useProductEntitlementDetails();
	const cloudId = useCloudId();
	const { serviceDesk: jsmEdition } = useAppEditions();

	useEffect(() => {
		if (isUserEligibleOnStandardPaid) {
			setIsUserOnPremiumTrial(false);
			return;
		}
		const loadPremiumTrialStatus = async () => {
			try {
				if (jsmEdition !== PREMIUM_EDITION) {
					setIsUserOnPremiumTrial(false);
					return;
				}
				const entitlementDetails = productEntitlementDetails?.[SERVICE_DESK];
				const { billingSourceSystem, trialEndTime } = entitlementDetails || {};
				// CCP Billing System
				if (billingSourceSystem === 'CCP' && trialEndTime) {
					setIsUserOnPremiumTrial(true);
				} else {
					if (!cloudId) {
						throw new Error('Empty cloudId');
					}
					// HAMS Billing System
					const hamsEntitlementDetails = await fetchHamsEntitlementDetails(cloudId);
					const jsmEntitlement = hamsEntitlementDetails?.entitlements?.find(
						(entitlement: { productKey: string }) => entitlement.productKey === JSM_PRODUCT_KEY,
					);
					const isHamsPremiumTrial =
						jsmEntitlement?.trialEditionEndDate || jsmEntitlement?.trialEndDate;
					setIsUserOnPremiumTrial(!!isHamsPremiumTrial);
				}
			} catch (e) {
				fireErrorAnalytics({
					meta: {
						id: 'useIsUserOnPremiumTrial',
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
					error: e instanceof Error ? e : undefined,
					sendToPrivacyUnsafeSplunk: true,
				});
			}
		};
		loadPremiumTrialStatus();
	}, [isUserEligibleOnStandardPaid, jsmEdition, productEntitlementDetails, cloudId]);

	return {
		isUserOnPremiumTrial,
	};
};
