import React, { useEffect } from 'react';
import EmptyState from '@atlaskit/empty-state';
import { NotFoundErrorPageAsync } from '@atlassian/jira-error-pages/src/async.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import ErrorIllustration from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/error/components/error';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { GlobalPageLoadExperience } from '@atlassian/ufo';
import { messages } from './messages';

type Props = {
	error: Error;
};

const ErrorContent = () => {
	const { formatMessage } = useIntl();

	const renderImage = () =>
		getAkEmptyStateRenderImageFn(ErrorIllustration)({
			imageWidth: 164,
			maxImageWidth: 164,
			maxImageHeight: 212,
		});

	return (
		<EmptyState
			size="wide"
			renderImage={renderImage}
			header={formatMessage(messages.errorTitle)}
			description={formatMessage(messages.errorDescription)}
		/>
	);
};

export const ErrorPageContent = ({ error }: Props) => {
	useEffect(() => {
		GlobalPageLoadExperience.failure({ force: true });
	});

	if (error instanceof FetchError && error.statusCode === 404) {
		return <NotFoundErrorPageAsync />;
	}

	return <ErrorContent />;
};
