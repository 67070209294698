import React from 'react';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { AsyncCreateObjectModal } from '@atlassian/jira-servicedesk-insight-object-create-modal/src/async';
import {
	useInsightObjectSchemaPageData,
	useSchemaPageUiState,
} from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services/index.tsx';
import { useObjectSearchStoreState } from '@atlassian/jira-servicedesk-insight-object-search-store/src/controllers/store/index.tsx';

export const CreateObjectModal = () => {
	const [{ objectSchemaId, selectedObjectTypeId }] = useSchemaPageUiState();
	const [{ isCreateObjectOpen }, { fetchSchemaObjectTypes, closeCreateObject }] =
		useInsightObjectSchemaPageData();
	const [, { refreshSearch }] = useObjectSearchStoreState();

	if (selectedObjectTypeId === null) {
		return null;
	}

	const onClose = () => {
		closeCreateObject();
	};
	const onSuccess = () => {
		refreshSearch();
		fetchSchemaObjectTypes();
	};

	return (
		<ModalTransition>
			{isCreateObjectOpen && (
				<AsyncCreateObjectModal
					objectSchemaId={objectSchemaId}
					objectTypeId={selectedObjectTypeId}
					onClose={onClose}
					onSuccess={onSuccess}
				/>
			)}
		</ModalTransition>
	);
};
