import type { CmdbLimitState } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/global-config.tsx';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import { LOCAL_STORAGE, DISMISSED_FOR_THRESHOLD_TYPE_KEY } from '../../../constants';
import type { ActionApi, BillingPeriod } from '../../../types';
import { determineThreshold } from '../../../usage-info/index.tsx';

export const setInitialData =
	({
		isJiraAdmin,
		objectCount,
		objectLimit,
		billingPeriod,
		limitState,
		workspaceId,
	}: {
		isJiraAdmin: boolean;
		objectCount: number;
		objectLimit: number;
		billingPeriod: BillingPeriod;
		limitState: CmdbLimitState;
		workspaceId: WorkspaceId;
	}): ActionApi =>
	async ({ setState }) => {
		const threshold = determineThreshold({ objectCount, objectLimit });
		const dismissState = LOCAL_STORAGE.get(DISMISSED_FOR_THRESHOLD_TYPE_KEY);
		const userRole = isJiraAdmin ? 'admin' : 'agent';
		if (
			dismissState &&
			dismissState !== threshold &&
			threshold !== 'none' &&
			limitState !== 'disabled'
		) {
			LOCAL_STORAGE.remove(DISMISSED_FOR_THRESHOLD_TYPE_KEY);
		}

		setState({
			userRole,
			billingPeriod,
			objectCount,
			objectLimit,
			isDismissed: dismissState === threshold,
			limitState,
			workspaceId,
		});
	};
