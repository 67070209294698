import type { MessageDescriptorV2 } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { TaskOperationType } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task';
import messages from '../../../../../common/messages';

type ActionMessages = {
	[key in TaskOperationType]: MessageDescriptorV2;
};

export const mapActionToSuccessMessage = (
	operationType: TaskOperationType,
): MessageDescriptorV2 => {
	const actionMessages: ActionMessages = {
		BulkExport: messages.submitSuccessFlagTitleBulkExport,
		QrCode: messages.submitSuccessFlagTitleBulkQrCode,
		BulkEdit: messages.submitSuccessFlagTitleBulkEdit,
		BulkDelete: messages.submitSuccessFlagTitleBulkDelete,
	};
	return actionMessages[operationType];
};

export const mapActionToErrorMessage = (operationType: TaskOperationType): MessageDescriptorV2 => {
	const actionMessages: ActionMessages = {
		BulkExport: messages.submitErrorFlagTitleBulkExport,
		QrCode: messages.submitErrorFlagTitleBulkQrCode,
		BulkEdit: messages.submitErrorFlagTitleBulkEdit,
		BulkDelete: messages.submitErrorFlagTitleBulkDelete,
	};
	return actionMessages[operationType];
};
