import React from 'react';
import { lazy } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { ObjectSchemaQuickSearchProps } from './common/types/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyCmdbObjectSchemaQuickSearch = lazy(() =>
	import(/* webpackChunkName: "async-cmdb-object-schema-quick-search" */ './ui/index.tsx').then(
		({ ObjectSchemaQuickSearch }) => ObjectSchemaQuickSearch,
	),
);

export const AsyncCmdbObjectSchemaQuickSearch = (props: ObjectSchemaQuickSearchProps) => (
	<Placeholder fallback={null} name="lazy-cmdb-object-schema-quick-search">
		<LazyCmdbObjectSchemaQuickSearch {...props} />
	</Placeholder>
);
