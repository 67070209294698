import {
	performGetRequest,
	performPostRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { getCmdbDescribeRolesUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/describe.tsx';
import { getCmdbObjectSchemaByIdObjectTypesFlatUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/object-schema.tsx';
import type { ObjectType as ObjectTypeResponse } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/object-type.tsx';
import type { RolesResponse } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/roles-response.tsx';
import type {
	SchemaId,
	WorkspaceId,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type { ObjectTypesById } from '../../common/types';
import { transformObjectTypeResponse } from '../../common/utils';

export const fetchObjectTypes = async (
	workspaceId: WorkspaceId,
	objectSchemaId: SchemaId,
): Promise<ObjectTypesById> => {
	const [objectTypeResponses, schemaRolesResponse]: [ObjectTypeResponse[], RolesResponse] =
		await Promise.all([
			performGetRequest(
				getCmdbObjectSchemaByIdObjectTypesFlatUrl(workspaceId, objectSchemaId, {
					includeObjectCounts: true,
				}),
			),
			performPostRequest(getCmdbDescribeRolesUrl(workspaceId), {
				body: JSON.stringify({
					objectSchemaRequests: [
						{
							resourceId: objectSchemaId,
						},
					],
				}),
			}),
		]);

	return objectTypeResponses.reduce<ObjectTypesById>((acc, objectTypeResponse) => {
		const role = schemaRolesResponse?.objectTypeRoles?.find(
			({ resourceId }) => resourceId === objectTypeResponse.id,
		)?.role;
		const objectType = transformObjectTypeResponse(objectTypeResponse, role || 'OBJECT_TYPE_USER');
		acc[objectType.id] = objectType;
		return acc;
	}, {});
};
