import React, { useLayoutEffect } from 'react';
import { loadSchemaObjectListExperience } from '@atlassian/jira-servicedesk-insight-experiences';
import { InsightObjectSchemaListSkeleton } from '@atlassian/jira-skeletons/src/ui/insight-object-schema/index.tsx';
import type { ObjectSkeletonProps } from '../../types';

export const ObjectListSkeleton = ({ startExperience }: ObjectSkeletonProps) => {
	useLayoutEffect(() => {
		if (startExperience) {
			loadSchemaObjectListExperience.abort();
			loadSchemaObjectListExperience.start();
		}
	}, [startExperience]);

	return <InsightObjectSchemaListSkeleton />;
};
