import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getCmdbGlobalConfigObjectUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/global.tsx';
import { getCmdbObjectSchemaByIdUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/object-schema.tsx';
import type { CmdbIcon } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/cmdb-object.tsx';
import type { CmdbLimitInfo } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/global-config.tsx';
import {
	type SchemaId,
	toSchemaId,
	type WorkspaceId,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import { isSignal } from '@atlassian/jira-servicedesk-insight-shared-utils/src/common/utils/use-fetch-error/index.tsx';
import type { ActionApi, ObjectTypesById, SuccessState } from '../../common/types';
import { fetchObjectTypes } from '../../services/fetch-object-types';

// Note: this response includes an 'objectSchema' property like ObjectSchemaResponse below, however can't be used
// because some information is missing from it, e.g. objectCount and objectTypeCount.
type SchemaGlobalConfigResponse = {
	icons: CmdbIcon[];
	insightAdministrator: boolean;
	insightManager: boolean;
	insightDeveloper: boolean;
	restrictedObjectSchemaIds: number[];
	limitInfo: CmdbLimitInfo;
};
const fetchSchemaGlobalConfig = async (
	workspaceId: WorkspaceId,
	objectSchemaId: SchemaId,
): Promise<SchemaGlobalConfigResponse> => {
	const {
		icons,
		insightAdministrator,
		insightManager,
		insightDeveloper,
		restrictedObjectSchemaIds,
		limitInfo,
	}: SchemaGlobalConfigResponse = await performGetRequest(
		getCmdbGlobalConfigObjectUrl(workspaceId, { objectschemaid: objectSchemaId }),
	);

	// Explicitly extract the attributes that are used, to avoid adding in unused attributes
	const iconListWithServiceOTEntriesRemoved =
		icons?.filter(
			(item) =>
				item.name !== 'Applications' &&
				item.name !== 'Business Services' &&
				item.name !== 'Capabilities' &&
				item.name !== 'Software Services',
		) || [];

	return {
		icons: iconListWithServiceOTEntriesRemoved,
		insightAdministrator,
		insightManager,
		insightDeveloper,
		restrictedObjectSchemaIds,
		limitInfo,
	};
};

type ObjectSchemaResponse = {
	id: SchemaId;
	idAsInt: number;
	name: string;
	objectCount: number;
	objectSchemaKey: string;
	objectTypeCount: number;
	atlassianTemplateId?: string;
};
const fetchObjectSchema = async (
	workspaceId: WorkspaceId,
	objectSchemaId: SchemaId,
): Promise<ObjectSchemaResponse> => {
	// Explicitly extract the attributes that are used, to avoid adding in unused attributes
	const { id, idAsInt, name, objectCount, objectSchemaKey, objectTypeCount, atlassianTemplateId } =
		await performGetRequest(getCmdbObjectSchemaByIdUrl(workspaceId, objectSchemaId));
	return {
		id,
		idAsInt,
		name,
		objectCount,
		objectSchemaKey,
		objectTypeCount,
		atlassianTemplateId,
	};
};

const pageDataStateFromResponses = (
	schemaGlobalConfigResponse: SchemaGlobalConfigResponse,
	objectSchemaResponse: ObjectSchemaResponse,
	objectTypesById: ObjectTypesById,
): SuccessState => {
	const { icons, insightAdministrator, insightManager, insightDeveloper, limitInfo } =
		schemaGlobalConfigResponse;
	const restrictedObjectSchemaIds = schemaGlobalConfigResponse.restrictedObjectSchemaIds.map((id) =>
		toSchemaId(String(id)),
	);
	return {
		type: 'success',
		schemaGlobalConfig: {
			icons,
			insightAdministrator,
			insightManager,
			insightDeveloper,
			limitInfo,
		},
		objectSchema: {
			...objectSchemaResponse,
			isRestricted: restrictedObjectSchemaIds.includes(objectSchemaResponse.id),
		},
		objectTypesById,
		restrictedObjectSchemaIds,
	};
};

export const fetchSchemaPageData =
	(): ActionApi =>
	async ({ setState }, { workspaceId, objectSchemaId, createAnalyticsEvent }) => {
		try {
			const schemaGlobalConfigPromise = fetchSchemaGlobalConfig(workspaceId, objectSchemaId);
			const objectSchemaPromise = fetchObjectSchema(workspaceId, objectSchemaId);
			const objectTypesPromise = fetchObjectTypes(workspaceId, objectSchemaId);
			const [schemaGlobalConfigResponse, objectSchemaResponse, objectTypesById]: [
				SchemaGlobalConfigResponse,
				ObjectSchemaResponse,
				ObjectTypesById,
			] = await Promise.all([schemaGlobalConfigPromise, objectSchemaPromise, objectTypesPromise]);

			setState({
				pageDataState: pageDataStateFromResponses(
					schemaGlobalConfigResponse,
					objectSchemaResponse,
					objectTypesById,
				),
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'fetchInsightObjectSchemaPageData succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				pageDataState: { type: 'error', error },
			});
			if (fg('fly-2232_improve_ttd')) {
				if (isSignal(error)) {
					fireErrorAnalytics({
						meta: {
							id: 'fetchInsightObjectSchemaPageData',
							packageName: 'jiraServicedeskInsightObjectSchemaPageStore',
							teamName: 'falcons',
						},
						error,
					});
				}
			} else {
				fireErrorAnalytics({
					meta: {
						id: 'fetchInsightObjectSchemaPageData',
						packageName: 'jiraServicedeskInsightObjectSchemaPageStore',
						teamName: 'falcons',
					},
					error,
				});
			}
		}
	};
