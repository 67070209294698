import type { AccountType } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';

export const UserActor = 'atlassian-user-role-actor' as const;
export const GroupActor = 'atlassian-group-role-actor' as const;

export type Group = {
	groupId?: string;
	name: string;
};

export type RoleActorType = {
	id: string;
	displayName: string;
	avatarUrl?: string;
	name: string;
	type: typeof UserActor | typeof GroupActor;
	accountType?: AccountType;
};

export const Role = {
	USER: 'user',
	DEVELOPER: 'developer',
	MANAGER: 'manager',
} as const;
export type Role = (typeof Role)[keyof typeof Role];

export const RESOURCE_TYPE = {
	SCHEMA: 'schema',
	WORKSPACE: 'workspace',
} as const;

export type ResourceType = (typeof RESOURCE_TYPE)[keyof typeof RESOURCE_TYPE];
