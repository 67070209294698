import { LOCAL_STORAGE, DISMISSED_FOR_THRESHOLD_TYPE_KEY } from '../../../constants';
import type { ActionApi } from '../../../types';
import { determineThreshold } from '../../../usage-info/index.tsx';

export const dismissMessage =
	(): ActionApi =>
	async ({ setState, getState }) => {
		const currentState = getState();
		const { objectCount, objectLimit } = currentState;

		const threshold = determineThreshold({ objectCount, objectLimit });
		const isDismissed = LOCAL_STORAGE.get(DISMISSED_FOR_THRESHOLD_TYPE_KEY) === threshold;

		if (!isDismissed) {
			LOCAL_STORAGE.set(DISMISSED_FOR_THRESHOLD_TYPE_KEY, threshold);
			setState({
				...currentState,
				isDismissed: true,
			});
		}
	};
