import { stringify as qsStringify } from 'query-string';
import type {
	CmdbObjectTypeId,
	WorkspaceId,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type { ObjectTypeByIdAttributesRequest } from '../types/object-type';
import { getCmdbRestBaseUrl } from './index';

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/objecttype
 */
const getCmdbObjectTypeUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbRestBaseUrl(workspaceId)}/objecttype`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/objecttype/${objectTypeId}
 */
export const getCmdbObjectTypeByIdUrl = (
	workspaceId: WorkspaceId,
	objectTypeId: CmdbObjectTypeId,
) => `${getCmdbObjectTypeUrl(workspaceId)}/${objectTypeId}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/objecttype/${objectTypeId}/attributes
 */
export const getCmdbObjectTypeByIdAttributesUrl = (
	workspaceId: WorkspaceId,
	objectTypeId: CmdbObjectTypeId,
	queryParams: ObjectTypeByIdAttributesRequest,
) =>
	`${getCmdbObjectTypeByIdUrl(workspaceId, objectTypeId)}/attributes?${qsStringify(queryParams)}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/objecttype/${objectTypeId}/delete/objects
 */
export const getCmdbObjectTypeByIdDeleteObjectsUrl = (
	workspaceId: WorkspaceId,
	objectTypeId: CmdbObjectTypeId,
) => `${getCmdbObjectTypeByIdUrl(workspaceId, objectTypeId)}/delete/objects`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/objecttype/${objectTypeId}/objects
 */
export const getCmdbObjectTypeByIdObjectsUrl = (
	workspaceId: WorkspaceId,
	objectTypeId: CmdbObjectTypeId,
) => `${getCmdbObjectTypeByIdUrl(workspaceId, objectTypeId)}/objects`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/objecttype/${objectTypeId}/position
 */
export const getCmdbObjectTypeByIdPositionUrl = (
	workspaceId: WorkspaceId,
	objectTypeId: CmdbObjectTypeId,
) => `${getCmdbObjectTypeByIdUrl(workspaceId, objectTypeId)}/position`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/objecttype/copy
 */
export const getCmdbObjectTypeCopyUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbObjectTypeUrl(workspaceId)}/copy`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/objecttype/create
 */
export const getCmdbObjectTypeCreateUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbObjectTypeUrl(workspaceId)}/create`;

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/objecttype/bulkedit
 */
export const getCmdbObjectTypeBulkEditUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbObjectTypeUrl(workspaceId)}/bulkedit`;
