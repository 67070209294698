import type { ObjectSchema } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/object-schema.tsx';
import type { SchemaId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type { NonSchemaAnalyticsAttributes, SchemaAnalyticsAttributes } from './types';

/**
 * This method should only be used outside the context of a schema (e.g. the
 * landing page), if the full schema is available, you should use
 * {@link getCmdbAnalyticAttributesFromSchema} instead
 */
export const getCmdbAnalyticAttributesFromRestrictedSchemaIds = (
	restrictedObjectSchemaIds: SchemaId[],
	schemaId: SchemaId,
): NonSchemaAnalyticsAttributes => ({
	cmdbUiSource: restrictedObjectSchemaIds.includes(schemaId) ? 'service' : 'insight',
});

/**
 * This method should be used when inside the context of a schema (e.g. the
 * schema/object pages). If the full schema is not available, you should use
 * {@link getCmdbAnalyticAttributesFromRestrictedSchemaIds} instead.
 */
export const getCmdbAnalyticAttributesFromSchema = (
	/** This is set to partial due to ObjectSchema from store and ObjectSchema from shared-types being incompatible */
	schema: Partial<ObjectSchema>,
): SchemaAnalyticsAttributes => ({
	cmdbUiSource: schema.isRestricted ? 'service' : 'insight',
	atlassianTemplateId: schema.atlassianTemplateId,
});
