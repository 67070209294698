import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { getCmdbAsyncBulkQrCodeUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/object';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type { BulkActionResponse } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import { generateFilterBody } from '../common/utils/generate-filter-body';
import type { AsyncBulkQrCodeParams } from './types';

export const createAsyncBulkQrCodeTask = (
	workspaceId: WorkspaceId,
	size: number,
	params: AsyncBulkQrCodeParams,
): Promise<BulkActionResponse> => {
	if (params.qlQueryParams == null && params.filterParams == null) {
		throw new Error(
			'Must supply either `filters` or `qlQueryParams` to `createAsyncBulkQrCodeTask`',
		);
	}

	const body = generateFilterBody(params);

	return performPostRequest(getCmdbAsyncBulkQrCodeUrl(workspaceId, { size }), {
		body: JSON.stringify(body),
	});
};
