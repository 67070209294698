import {
	toValidatedIql,
	type ValidatedIql,
} from '@atlassian/jira-servicedesk-insight-iql-validation/src/common/types.tsx';
import type { ObjectAttributeResponse } from '@atlassian/jira-servicedesk-insight-object-types/src/common/types/object-details.tsx';
import {
	normalizeObjectAttributeResponse,
	toAttributeType,
} from '@atlassian/jira-servicedesk-insight-object-types/src/common/utils/normalization/index.tsx';
import type {
	CmdbObjectTypeId,
	SchemaId,
	SortDirection,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type {
	AttributeFiltersByName,
	AttributesTarget,
	AvatarColumnTarget,
	ColumnTarget,
	OrderBy,
	SearchResultObject,
	SearchResultObjectTypeAttribute,
	SearchScope,
	SearchScopeSchemaObject,
	TransformedSearchResponse,
} from '../types';
import type {
	ObjectEntryResponse,
	SearchResponse,
	SearchObjectTypesResponse,
} from '../types/responses';
import { filterFromResponse } from './filters';
import { formatSelectedObjectTypesForResponse } from './filters/object-type/index.tsx';

/**
 * A single place to get the object schema and object type from the search scope.
 * For now, the store only supports single schema and object type search. This should be removed once cross-schema
 * search is implemented.
 */
export const checkIsSupportedSearchScope = (searchScope: SearchScope): SearchScopeSchemaObject => {
	if (searchScope.type === 'global') {
		throw new Error('checkSearchScope - global search is not yet supported');
	}
	const { objectSchemaId, objectTypeId, isObjectTypeInherited } = searchScope;

	return { objectSchemaId, objectTypeId, isObjectTypeInherited };
};

/**
 * Given an IQL query, extracts the ORDER BY part from it if there is one.
 */
export const extractOrderBy = (
	iql: ValidatedIql,
): {
	iqlWithoutOrderBy: ValidatedIql;
	orderBy: {
		attributeName: string;
		direction: SortDirection;
	} | null;
} => {
	const [beforeOrder, attributeNameAndDirection = null] = String(iql).split(/\s*order\s+by\s+/gi);

	if (attributeNameAndDirection === null) {
		return {
			iqlWithoutOrderBy: iql,
			orderBy: null,
		};
	}

	const [maybeQuotedName, optionalDirection = 'ASC'] = attributeNameAndDirection
		.trim()
		.split(/\s+/g);
	const direction: SortDirection =
		optionalDirection.trim().toLowerCase() === 'desc' ? 'DESC' : 'ASC';
	const attributeName = maybeQuotedName.replace(/^"/g, '').replace(/"$/g, '').trim();

	return {
		iqlWithoutOrderBy: toValidatedIql(beforeOrder.trim()),
		orderBy: {
			attributeName,
			direction,
		},
	};
};

type AttributesById = {
	[id: string]: SearchResultObjectTypeAttribute;
};
export const transformSearchResponse = (
	searchResponse: SearchResponse,
	objectTypesForFiltering: SearchObjectTypesResponse | null,
): TransformedSearchResponse => {
	const {
		objectEntries,
		objectTypeAttributes,
		pageNumber,
		pageSize,
		qlQuery,
		filters = null,
		orderByTypeAttrId,
		orderWay,
		totalFilterCount,
		hasMoreResults,
		startIndex,
		objectTypeId,
		objectTypeIsInherited,
		toIndex,
	} = searchResponse;
	const searchResultAttributes: SearchResultObjectTypeAttribute[] = [];
	const attributesById: AttributesById = {};
	// we only want to push the object type filter if there are other attributes
	objectTypeAttributes.forEach((attributeResponse) => {
		const { id, name, system, label, type, summable, maximumCardinality } = attributeResponse;
		const attributeType = toAttributeType(type);
		let defaultType: SearchResultObjectTypeAttribute['defaultType'];
		if (attributeType === 'default' && attributeResponse.defaultType) {
			defaultType = attributeResponse.defaultType;
		}

		const objectTypeAttribute: SearchResultObjectTypeAttribute = {
			id,
			name,
			isSystem: system,
			isLabel: label,
			attributeType,
			defaultType,
			isSummable: summable,
			maximumCardinality,
		};
		searchResultAttributes.push(objectTypeAttribute);
		attributesById[id] = objectTypeAttribute;
	});

	let orderBy: OrderBy | null = null;
	if (orderByTypeAttrId != null) {
		const orderByAttribute = attributesById[orderByTypeAttrId];
		if (orderByAttribute) {
			orderBy = {
				direction: orderWay === 'ascending' ? 'ASC' : 'DESC',
				objectTypeAttributeId: orderByTypeAttrId,
				attributeName: orderByAttribute.name,
			};
		}
	}

	const { iqlWithoutOrderBy } = extractOrderBy(qlQuery);
	const canConvertQlQueryToFilter =
		filters !== null && !(filters.length === 0 && iqlWithoutOrderBy.length > 0);

	const objectTypeValues = formatSelectedObjectTypesForResponse({
		filters,
		objectTypesForFiltering,
	});

	return {
		objects: objectEntries.map<SearchResultObject>((objectEntry: ObjectEntryResponse) => {
			const { id, objectKey, avatar, attributes, label, objectType } = objectEntry;

			return {
				id,
				label,
				objectKey,
				avatar,
				attributes: attributes.map((attribute: ObjectAttributeResponse) =>
					normalizeObjectAttributeResponse(attribute),
				),
				objectTypeId: objectType.id,
			};
		}),
		attributes: searchResultAttributes,
		qlQuery,
		filters: (filters || []).reduce((acc, filterResponse) => {
			const { objectTypeAttributeId, selectedValues } = filterResponse;
			const objectTypeAttribute = attributesById[objectTypeAttributeId];
			if (!objectTypeAttribute) {
				return acc;
			}

			const { name } = objectTypeAttribute;
			const attributeFilter = filterFromResponse({
				objectTypeAttribute,
				selectedValues,
			});
			acc[name] = {
				objectTypeAttributeId,
				attributeFilter,
			};
			return acc;
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		}, {} as AttributeFiltersByName),
		canConvertQlQueryToFilter,
		objectTypeIsInherited,
		objectTypeId,
		pageNumber,
		numObjects: totalFilterCount,
		numPages: pageSize,
		hasMoreResults: hasMoreResults ?? false,
		orderBy,
		startIndex,
		toIndex,
		objectTypes: objectTypeValues,
	};
};

export const isAttributeColumnTarget = (target: ColumnTarget): target is AttributesTarget =>
	target.type === 'attribute';

/**
 *
 * Compares two columns and checks if they are the same by comparing the type if it's an avatar
 * and compares by name if it's an attribute
 */
export const isSameColumn = (
	selectedColumn: ColumnTarget,
	originalColumn: (SearchResultObjectTypeAttribute & { type: 'attribute' }) | AvatarColumnTarget,
) => {
	const isAttributeSelectedColumn = isAttributeColumnTarget(selectedColumn);
	const isAttributeOriginalColumn = originalColumn.type === 'attribute';

	// if it's not an attribute column compare by type
	if (!isAttributeSelectedColumn || !isAttributeOriginalColumn) {
		return selectedColumn.type === originalColumn.type;
	}

	// only compare by name if it's an attribute column
	return selectedColumn.name === originalColumn.name;
};

const QLQUERY_CONCATENATOR = ' AND ';

const generateObjectSchemaClause = (objectSchemaId: SchemaId) =>
	`objectSchemaId = ${objectSchemaId}`;

const generateObjectTypeClause = (
	objectTypeId: CmdbObjectTypeId,
	isObjectTypeInherited: boolean,
) => {
	if (isObjectTypeInherited) {
		return `objectType IN objectTypeAndChildren(${objectTypeId})`;
	}
	return `objectTypeId = ${objectTypeId}`;
};

/**
 *
 * Currently the objectSchemaId and objectTypeId are sent as separate values in the payload for /object/navlist
 * and the returned QlQuery from the server omits these clauses in the response.
 * The https://developer.atlassian.com/cloud/assets/rest/api-group-object/#api-object-aql-totalcount-post
 * only accepts a qlQuery string so we need to generate this query on the FE ourselves
 * Implementing the same logic here found in
 * jsm-cmdb-monolith/insight-aql/src/main/java/com/riadalabs/jira/plugins/insight/services/core/iql/model/IQL.java
 */
export const generateQlQueryForSearchScope = (
	currentQlQuery: string,
	{ objectSchemaId, objectTypeId, isObjectTypeInherited }: SearchScopeSchemaObject,
	includeOrderBy = false,
) => {
	const { iqlWithoutOrderBy: qlQueryWithoutOrderBy, orderBy } = extractOrderBy(currentQlQuery);
	const qlQueryWithSearchScopeClauses = [
		// Only add existing qlQuery if not empty
		...(qlQueryWithoutOrderBy ? [qlQueryWithoutOrderBy] : []),
		generateObjectSchemaClause(objectSchemaId),
		generateObjectTypeClause(objectTypeId, isObjectTypeInherited),
	].join(QLQUERY_CONCATENATOR);
	const orderByClause =
		includeOrderBy && orderBy ? ` ORDER BY "${orderBy.attributeName}" ${orderBy.direction}` : '';
	return `${qlQueryWithSearchScopeClauses}${orderByClause}`;
};
