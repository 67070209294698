import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { updateObjectFilterExperience } from '@atlassian/jira-servicedesk-insight-experiences';
import { useInsightObjectSchemaPageData } from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services/index.tsx';
import type {
	AttributesTarget,
	Column,
	SearchResult,
	SearchResultObject,
} from '@atlassian/jira-servicedesk-insight-object-search-store/src/common/types';
import { useObjectSearchStoreState } from '@atlassian/jira-servicedesk-insight-object-search-store/src/controllers/store/index.tsx';
import { getOrderBy } from '@atlassian/jira-servicedesk-insight-object-search-store/src/services/selectors/index.tsx';
import { AsyncObjectTable } from '@atlassian/jira-servicedesk-insight-object-table/src/async.tsx';
import type {
	ObjectRow,
	OrderBy as ObjectTableOrderBy,
} from '@atlassian/jira-servicedesk-insight-object-table/src/common/types.tsx';
import messages from './messages';

const searchResultsToObjectRows = (objectSearchResults: SearchResultObject[]): ObjectRow[] =>
	objectSearchResults.map<ObjectRow>(({ id, avatar, attributes, label, objectTypeId }) => {
		const attributesByName = attributes.reduce<ObjectRow['attributesByName']>(
			(acc, attribute) => ({
				// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
				...acc,
				[attribute.objectTypeAttribute.name]: attribute,
			}),
			{},
		);
		return { id, avatar, attributesByName, label, objectTypeId };
	});

type ObjectTableProps = {
	searchResult: SearchResult;
	columns: Column[];
};

export const ObjectTable = ({ searchResult, columns }: ObjectTableProps) => {
	const { showFlag } = useFlagsService();
	const [
		searchStoreState,
		{ updateOrderBy, refreshSearch, triggerSuccessfulObjectFilterExperience },
	] = useObjectSearchStoreState();
	const { filtersUpdated } = searchStoreState;
	const [, { fetchSchemaObjectTypes }] = useInsightObjectSchemaPageData();

	const onChangeObjects = () => {
		refreshSearch();
		// This is a bit indirect - we re-fetch the object types in the schema to ensure the object count badge is updated
		fetchSchemaObjectTypes();
	};

	useEffect(() => {
		if (filtersUpdated) {
			updateObjectFilterExperience.mark('object-table-rendered');
			triggerSuccessfulObjectFilterExperience();
		}
	}, [filtersUpdated, triggerSuccessfulObjectFilterExperience]);

	const onChangeOrderBy = async (objectTableOrderBy: ObjectTableOrderBy) => {
		try {
			const sortByAttribute = searchResult.attributes.find(
				({ name }) => name === objectTableOrderBy.attributeName,
			);
			if (!sortByAttribute) {
				throw new Error('Failed to find attribute by name for sorting');
			}

			await updateOrderBy({
				...objectTableOrderBy,
				objectTypeAttributeId: sortByAttribute.id,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics({
				meta: {
					id: 'onChangeOrderBy',
					packageName: 'jiraServicedeskInsightObjectSchemaPage',
					teamName: 'falcons',
				},
				error,
			});
			showFlag({
				type: 'error',
				title: messages.sortErrorTitle,
				description: messages.sortErrorDescription,
				isAutoDismiss: true,
			});
		}
	};
	const selectedColumns = columns.filter(({ isSelected }) => isSelected);

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const attributeTargets = selectedColumns
		.map(({ target }) => target)
		.filter(({ type }) => type !== 'avatar') as AttributesTarget[];

	const [attributeLabelColumn] = attributeTargets.filter(({ isLabel }) => isLabel);

	const { objects } = searchResult;

	const orderBy = getOrderBy(searchStoreState);
	// Note: the object table OrderBy type is slightly different - it doesn't include the objectTypeAttributeId because
	// we want to move towards referencing object type attributes by name instead of ID.
	const objectTableOrderBy: ObjectTableOrderBy | null =
		orderBy !== null
			? {
					attributeName: orderBy.attributeName,
					direction: orderBy.direction,
				}
			: null;
	return (
		<Container>
			<AsyncObjectTable
				columns={selectedColumns}
				attributeLabelName={attributeLabelColumn?.name ?? null}
				objects={searchResultsToObjectRows(objects)}
				orderBy={objectTableOrderBy}
				onChangeOrderBy={(newOrderBy) => {
					onChangeOrderBy(newOrderBy);
				}}
				onChangeObjects={onChangeObjects}
			/>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.100', '8px'),
});
