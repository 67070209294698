import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { getCmdbUserHistoryViewedUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/user-history-rest.tsx';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';

export type EntityType = 'object' | 'object-type' | 'object-schema';

export const trackView = async ({
	workspaceId,
	entityId,
	entityType,
}: {
	workspaceId: WorkspaceId;
	entityId: string;
	entityType: EntityType;
}): Promise<void> => {
	await performPostRequest(getCmdbUserHistoryViewedUrl(workspaceId), {
		body: JSON.stringify({ entityId, entityType }),
	});
};
