import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { getCmdbAsyncBulkEditUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/object';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type { BulkActionResponse } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import { UNUSED_REQUIRED_PROPERTIES } from '../common/constants';
import type { AsyncBulkEditParams } from './types';

export const createAsyncBulkEditTask = (
	workspaceId: WorkspaceId,
	params: AsyncBulkEditParams,
): Promise<BulkActionResponse> =>
	performPostRequest(getCmdbAsyncBulkEditUrl(workspaceId), {
		body: JSON.stringify({
			objectEntry: {
				objectTypeId: params.objectTypeId,
				attributes: params.attributeOperations,
			},
			filters: {
				objectSchemaId: params.objectSchemaId,
				qlQuerySearch: true,
				qlQueryParams: {
					objectSchemaId: params.objectSchemaId,
					objectTypeId: params.objectTypeId,
					qlQuery: params.qlQuery,
					// The following attributes aren't relevant for bulk-update but are still required by the endpoint
					...UNUSED_REQUIRED_PROPERTIES,
				},
			},
		}),
	});
