import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { ActionApi, ObjectType, ObjectTypesById } from '../../common/types';
import { fetchObjectTypes } from '../../services/fetch-object-types';
import { getPageDataOrThrow } from '../../services/utils';

export const addCreatedObjectType =
	(newObjectType: ObjectType): ActionApi =>
	async (
		{ getState, setState },
		{ workspaceId, objectSchemaId, createAnalyticsEvent, setObjectTypeId, setObjectId },
	) => {
		try {
			const pageDataBeforeFetch = getPageDataOrThrow(getState());
			const updatedObjectTypesById: ObjectTypesById = {
				...pageDataBeforeFetch.objectTypesById,
				[String(newObjectType.id)]: newObjectType,
			};
			setState({
				pageDataState: {
					...pageDataBeforeFetch,
					objectTypesById: updatedObjectTypesById,
				},
				lastCreatedObjectTypeId: newObjectType.id,
			});
			// Select the newly-created object type, pushing a new browser history entry
			setObjectTypeId(newObjectType.id, 'push');
			// Need to wait for previous query param change to be applied before this one can due to a bug in react-resource-router
			setTimeout(() => setObjectId(null, 'replace'), 0);

			// Refetch object types to account for shuffling of positions
			const objectTypesById = await fetchObjectTypes(workspaceId, objectSchemaId);
			// Grab state again in case other properties have changed while fetching
			const pageDataAfterFetch = getPageDataOrThrow(getState());
			setState({
				pageDataState: {
					...pageDataAfterFetch,
					objectTypesById,
				},
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'addCreatedObjectType succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics({
				meta: {
					id: 'addCreatedObjectType',
					packageName: 'jiraServicedeskInsightObjectSchemaPageStore',
					teamName: 'falcons',
				},
				error,
			});
			// Re-throw error so that consumers can know if the action succeeded
			throw error;
		}
	};
