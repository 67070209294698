import React, {
	forwardRef,
	useCallback,
	useEffect,
	useState,
	type ForwardedRef,
	type MouseEvent,
} from 'react';
import { styled } from '@compiled/react';
import range from 'lodash/range';
import Pagination from '@atlaskit/pagination';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useObjectSearchPagination } from '@atlassian/jira-servicedesk-insight-object-search-store/src/controllers/store/index.tsx';
import { commonMessages } from '../../../../../../common/messages';
import { ObjectsPaginationLabel } from '../../../../../../common/ui/objects-pagination-label';
import { TotalObjectsPagination } from '../../../../../../common/ui/total-objects-pagination';
import { messages } from './messages';

export const PaginationFooter = () => {
	const [{ numPages, pageNumber, hasMoreResults }, { changePagination }] =
		useObjectSearchPagination();
	const { showFlag } = useFlagService();
	const { formatMessage } = useIntl();

	const [selectedPage, setSelectedPage] = useState(pageNumber);

	useEffect(() => {
		const loadNewPage = async () => {
			try {
				await changePagination(selectedPage);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				showFlag({
					type: 'error',
					title: messages.paginationErrorTitle,
					description: messages.paginationErrorDescription,
					isAutoDismiss: true,
				});
			}
		};
		selectedPage !== pageNumber && loadNewPage();
	}, [changePagination, pageNumber, selectedPage, showFlag]);

	const onPageChange = (event: MouseEvent<HTMLElement>, newPageNumber: number) => {
		setSelectedPage(newPageNumber);
	};

	const NextButtonRenderer = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(props: any, ref: ForwardedRef<HTMLElement>) =>
			props.disabled && hasMoreResults ? (
				<Tooltip content={formatMessage(commonMessages.disabledPaginationNextButton)}>
					<button
						{...props}
						ref={ref}
						aria-label={formatMessage(commonMessages.disabledPaginationNextButton)}
						data-testid="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-content.object-list.pagination-footer.button-disabled"
					/>
				</Tooltip>
			) : (
				<button
					{...props}
					ref={ref}
					data-testid="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-content.object-list.pagination-footer.button"
				/>
			),
		[formatMessage, hasMoreResults],
	);

	return (
		<Container>
			{fg('assets_rearch_total_count_deprecated_for_ui') ? (
				<TotalObjectsPagination />
			) : (
				<ObjectsPaginationLabel />
			)}
			<PaginationWrapper>
				<Pagination
					testId="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-content.object-list.pagination-footer.pagination"
					pages={range(1, numPages + 1)}
					// @ts-expect-error - TS2322 - Type '(event: MouseEvent<HTMLElement>, newPageNumber: number) => void' is not assignable to type '(event: SyntheticEvent<Element, Event>, page: number, analyticsEvent?: UIAnalyticsEvent | undefined) => void'.
					onChange={onPageChange}
					selectedIndex={pageNumber - 1}
					components={
						fg('assets_rearch_total_count_deprecated_for_ui')
							? { Next: forwardRef(NextButtonRenderer) }
							: undefined
					}
				/>
			</PaginationWrapper>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N200),
	display: 'flex',
	gap: token('space.100', '8px'),
	justifyContent: 'space-between',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PaginationWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	paddingTop: token('space.200', '16px'),
	paddingBottom: token('space.200', '16px'),
	paddingRight: token('space.600', '48px'),
});
