import React, { useMemo } from 'react';
import { SuccessFlag, type FlagProps } from '@atlassian/jira-flags';
import type { FlagCustom } from '@atlassian/jira-flags/src/services/types';
import { useIntl } from '@atlassian/jira-intl';
import type { CompletedBulkQrCodeTask } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task';
import { CompletedFlagDescription } from '../completed-flag-description';
import commonMessages from '../messages';
import type { BaseFlagProps } from '../types';
import messages from './messages';

export type CompletedFlagQrCodeProps = {
	task: CompletedBulkQrCodeTask;
} & BaseFlagProps;

export const CompletedFlagQrCode = ({
	task,
	taskUrl,
	flagProps,
	onDismissed,
}: CompletedFlagQrCodeProps) => {
	const { formatMessage } = useIntl();
	const itemRequestedCount = task.itemRequestedCount ?? 0;
	const flagActions: FlagProps['actions'] = useMemo(
		() => [
			{
				content: formatMessage(messages.downloadPdfNonfinal),
				href: taskUrl,

				download: true,
			},
		],
		[formatMessage, taskUrl],
	);
	return (
		<SuccessFlag
			{...flagProps}
			title={formatMessage(messages.flagTitle, {
				itemRequestedCount,
			})}
			id={task.identifier}
			description={
				<CompletedFlagDescription task={task}>
					{!task.hasError
						? formatMessage(commonMessages.completedFlagDescriptionNonfinal)
						: undefined}
				</CompletedFlagDescription>
			}
			actions={!task.hasError ? flagActions : undefined}
			onDismissed={onDismissed}
			testId="servicedesk-cmdb-async-actions.common.ui.flags.completed-flag-qr-code"
		/>
	);
};

export const qrCodeFlagHandler = (
	props: Omit<CompletedFlagQrCodeProps, 'flagProps'>,
): FlagCustom => ({
	id: props.task.identifier,
	render: (flagProps) => <CompletedFlagQrCode {...props} flagProps={flagProps} />,
});
