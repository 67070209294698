import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { IntercomChatWithErrorBoundry } from '@atlassian/jira-cmdb-intercom-integration/src/ui/index.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { DocumentTitle } from '@atlassian/jira-global-document-title';
import { useIntl } from '@atlassian/jira-intl';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { AsyncCmdbCompassChangeboarding } from '@atlassian/jira-servicedesk-cmdb-compass-changeboarding/src/async.tsx';
import { LimitsBanner } from '@atlassian/jira-servicedesk-cmdb-object-usage-messaging/src/ui/limits-banner/index.tsx';
import { ObjectUsageProvider } from '@atlassian/jira-servicedesk-cmdb-object-usage-messaging/src/utils/store/index.tsx';
import {
	useInsightVisibility,
	useWorkspaceContext,
} from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/controllers/workspace-context/index.tsx';
import {
	useInsightObjectSchemaPageData,
	useSchemaPageUiState,
	useLoadedSchemaPageData,
} from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services/index.tsx';
import {
	getCmdbAnalyticAttributes,
	matchPageDataState,
} from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services/selectors/index.tsx';
import { InsightObjectSchemaPageContainer } from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/ui/index.tsx';
import { toSchemaId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import { TrackObjectSchemaView } from '@atlassian/jira-servicedesk-insight-user-history-tracker/src/ui/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { usePathParam } from '@atlassian/react-resource-router';
import { ObjectSchemaPageLayout } from '../common/ui/object-schema-page-layout';
import {
	ErrorSideNavigation,
	LoadingSideNavigation,
	SideNavigation,
	SideNavigationWithErrorBoundary,
} from '../common/ui/side-navigation';
import { messages } from './messages';
import { ErrorPageContent } from './page-content/error/index.tsx';
import { LoadedPageContent } from './page-content/loaded/index.tsx';
import { LoadingPageContent } from './page-content/loading/index.tsx';
import { useViewModeLocalStorageSync } from './view-mode-sync';

export const ObjectSchemaPageContent = () => {
	const [schemaPageData] = useLoadedSchemaPageData();
	const [{ objectSchemaId }] = useSchemaPageUiState();
	const isSiteAdmin = useIsSiteAdmin();

	const cmdbAnalyticAttributes = {
		...getCmdbAnalyticAttributes(schemaPageData),
		isSiteAdmin,
	};

	return (
		<ContextualAnalyticsData
			sourceName="cmdbObjectSchema"
			sourceType={SCREEN}
			attributes={cmdbAnalyticAttributes}
		>
			<LoadedPageContent />
			<AsyncCmdbCompassChangeboarding />
			<TrackObjectSchemaView id={objectSchemaId} />
			<FireScreenAnalytics />
		</ContextualAnalyticsData>
	);
};

const RenderSchemaPageContent = () => {
	const { formatMessage } = useIntl();

	return (
		<JSErrorBoundary
			id="insightObjectSchemaPage"
			packageName="jiraServicedeskInsightObjectSchemaPage"
			teamName="falcons"
			fallback="flag"
		>
			<DocumentTitle title={formatMessage(messages.documentTitle)} />
			{getWillShowNav4() ? (
				<ObjectSchemaPageLayout
					data-testid="servicedesk-insight-object-schema-page.ui.object-schema-page-layout"
					leftComponent={<SideNavigationWithErrorBoundary />}
					rightComponent={<ObjectSchemaPageContent />}
				/>
			) : (
				<>
					<LimitsBanner displayWithOffset />
					<ObjectSchemaPageContent />
					<IntercomChatWithErrorBoundry />
				</>
			)}
		</JSErrorBoundary>
	);
};

export const ObjectSchemaPage = () => {
	const [{ pageDataState }] = useInsightObjectSchemaPageData();

	useViewModeLocalStorageSync();

	return matchPageDataState(pageDataState, {
		loading: () => (
			<ObjectViewLoadingContainer>
				<LoadingPageContent />
			</ObjectViewLoadingContainer>
		),
		error: (state) => <ErrorPageContent error={state.error} />,
		success: (state) => {
			if (fg('jsm_assets_cbp_limits_and_notifications')) {
				const {
					limitInfo: { objectCount, objectLimit, billingCycle, limitState = 'disabled' },
					insightAdministrator,
				} = state.schemaGlobalConfig;
				return (
					<ObjectUsageProvider
						isJiraAdmin={insightAdministrator}
						objectCount={objectCount}
						objectLimit={objectLimit}
						billingPeriod={billingCycle === 'annual' ? 'annual' : 'monthly'}
						limitState={limitState}
					>
						<RenderSchemaPageContent />
					</ObjectUsageProvider>
				);
			}
			return <RenderSchemaPageContent />;
		},
	});
};

export const ObjectSchemaSidebar = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [objectSchemaId] = usePathParam('objectSchemaId');
	const isInsightVisible = useInsightVisibility();
	const workspaceContext = useWorkspaceContext();
	if (workspaceContext.loading) {
		return <LoadingSideNavigation />;
	}
	if (workspaceContext.error) {
		return <ErrorSideNavigation />;
	}
	if (!isInsightVisible || objectSchemaId === undefined) {
		return null;
	}
	const { workspaceId } = workspaceContext;
	if (workspaceId === undefined) {
		return null;
	}
	const typedObjectSchemaId = toSchemaId(objectSchemaId);

	return (
		<InsightObjectSchemaPageContainer
			workspaceId={workspaceId}
			objectSchemaId={typedObjectSchemaId}
			createAnalyticsEvent={createAnalyticsEvent}
		>
			<SideNavigation />
		</InsightObjectSchemaPageContainer>
	);
};

// We need to offset the top margining that is coming from:
// src/packages/servicedesk/insight-object-schema/insight-object-schema-page/src/ui/page-content/loaded/object-view/styled.tsx
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ObjectViewLoadingContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	margin: `${gridSize * 3.5}px 0 ${token('space.200', '16px')}`,
});
