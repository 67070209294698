import React, { useEffect, useState } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { Box, xcss } from '@atlaskit/primitives';
import { PopupSelect } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import { useFlagService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { getUniqueIconUrl } from '@atlassian/jira-servicedesk-insight-icon/src/common/utils.tsx';
import { UnstyledInsightIcon } from '@atlassian/jira-servicedesk-insight-icon/src/ui/index.tsx';
import { useLoadedSchemaPageData } from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services/index.tsx';
import { canUpdateObjectType } from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services/selectors/index.tsx';
import type { CmdbIcon } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/cmdb-object.tsx';
import type { CmdbObjectTypeId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import { commonMessages } from '../../../../../../common/messages';
import { messages } from './messages';

const Icon = ({ icon }: { icon: CmdbIcon }) => (
	<UnstyledInsightIcon label={icon.name} iconUrl={getUniqueIconUrl(icon)} size="small" />
);

export const EditableIcon = ({ objectTypeId }: { objectTypeId: CmdbObjectTypeId }) => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();
	const [schemaPageData, { updateObjectType }] = useLoadedSchemaPageData();
	const {
		objectTypesById,
		schemaGlobalConfig: { icons },
	} = schemaPageData;
	const objectType = objectTypesById[objectTypeId];
	const [currentIconId, setCurrentIconId] = useState(objectType.icon.id);
	const currentIcon = icons.find(({ id }) => id === currentIconId) || objectType.icon;
	// Sync externally-changed object type icon into name state
	useEffect(() => {
		setCurrentIconId(objectType.icon.id);
	}, [objectType.icon.id]);

	if (!canUpdateObjectType(schemaPageData, objectTypeId)) {
		return <Icon icon={currentIcon} />;
	}

	const onChange = async ({ id: newIconId }: CmdbIcon) => {
		setCurrentIconId(newIconId);
		try {
			await updateObjectType({
				objectTypeId: objectType.id,
				updatedProperties: { iconId: newIconId },
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			showFlag({
				type: 'error',
				title: commonMessages.updateObjectTypeErrorTitle,
				description: commonMessages.updateObjectTypeErrorDescription,
				isAutoDismiss: true,
			});
			setCurrentIconId(objectType.icon.id);
		}
	};

	return (
		<div data-testid="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-header.editable-object-type.editable-icon.icon-select">
			{/* @ts-expect-error - TS2769 - No overload matches this call. */}
			<PopupSelect
				value={currentIconId}
				options={icons}
				placeholder={formatMessage(messages.searchPlaceholder)}
				formatOptionLabel={(icon: CmdbIcon) => (
					<IconOption>
						<Icon icon={icon} />
						<span>{icon.name}</span>
					</IconOption>
				)}
				isOptionSelected={(option: CmdbIcon) => option.id === currentIconId}
				onChange={onChange}
				target={({ isOpen: isOpenFromTriggerProps, ...triggerProps }: { isOpen: boolean }) => (
					<Box xcss={containerStyles}>
						<Button
							{...triggerProps}
							appearance="subtle"
							iconBefore={<Icon icon={currentIcon} />}
							isSelected={isOpenFromTriggerProps}
						/>
					</Box>
				)}
				filterOption={(
					{
						data,
					}: {
						data: CmdbIcon;
					},
					rawInput: string,
				) => {
					const trimmedLowercaseInput = rawInput.toLowerCase().trim();
					return (
						trimmedLowercaseInput.length === 0 ||
						data.name.toLowerCase().includes(trimmedLowercaseInput)
					);
				}}
			/>
		</div>
	);
};

const containerStyles = xcss({
	padding: 'space.025',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconOption = styled.div({
	display: 'flex',
	gap: token('space.100', '8px'),
	alignItems: 'center',
});
