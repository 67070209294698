import React from 'react';
import { useSchemaPageUiState } from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services/index.tsx';
import {
	InsightObjectSchemaHeaderSkeleton,
	InsightObjectSchemaAttributesSkeleton,
} from '@atlassian/jira-skeletons/src/ui/insight-object-schema/index.tsx';
import { InsightObjectViewSkeleton } from '@atlassian/jira-skeletons/src/ui/insight-object/index.tsx';
import { ObjectDetailsSkeleton } from '../../../common/ui/object-details-skeleton';
import { ObjectListSkeleton } from '../../../common/ui/object-list-skeleton';

export const LoadingPageContent = () => {
	const [{ objectId, schemaViewMode, objectViewMode }] = useSchemaPageUiState();

	if (objectViewMode === 'list' && objectId !== null) {
		return <InsightObjectViewSkeleton />;
	}

	const renderObjectViewModeSkeleton = () => {
		switch (objectViewMode) {
			case 'details': {
				return <ObjectDetailsSkeleton />;
			}
			case 'list':
				if (objectId !== null) {
					return <InsightObjectViewSkeleton />;
				}
				return <ObjectListSkeleton />;
			default:
				// prettier-ignore
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(objectViewMode as never);
				return null;
		}
	};

	const renderPageContentSkeleton = () => {
		switch (schemaViewMode) {
			case 'object': {
				return renderObjectViewModeSkeleton();
			}
			case 'attribute':
				return <InsightObjectSchemaAttributesSkeleton />;
			default:
				// prettier-ignore
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(schemaViewMode as never);
				return null;
		}
	};

	return (
		<>
			<InsightObjectSchemaHeaderSkeleton />
			{renderPageContentSkeleton()}
		</>
	);
};
