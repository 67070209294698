import React, { useEffect } from 'react';
import { loadObjectDetailExperience } from '@atlassian/jira-servicedesk-insight-experiences';
import { AsyncObjectView } from '@atlassian/jira-servicedesk-insight-object-view/src/async.tsx';
import type { ObjectViewProps } from '@atlassian/jira-servicedesk-insight-object-view/src/common/types.tsx';
import { useUFOComponentExperience } from '@atlassian/ufo';

export const EmbeddedObjectView = (props: ObjectViewProps) => {
	// Hook will ensure experience is aborted on unmount
	useUFOComponentExperience(loadObjectDetailExperience);
	// useEffect will restart experience whenever viewed object is changed
	useEffect(() => {
		loadObjectDetailExperience.abort();
		loadObjectDetailExperience.start();
	}, [props.objectId]);
	return <AsyncObjectView {...props} />;
};
