import React, { type ReactNode } from 'react';
import { Flex, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useWorkspaceContext } from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/controllers/workspace-context/index.tsx';
import {
	useLoadedSchemaPageData,
	useSchemaPageUiState,
} from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services/index.tsx';
import { isObjectTypeInherited } from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services/selectors/index.tsx';
import { InsightObjectSearchStore } from '@atlassian/jira-servicedesk-insight-object-search-store/src/controllers/store/index.tsx';
import { TrackObjectTypeView } from '@atlassian/jira-servicedesk-insight-user-history-tracker/src/ui/index.tsx';
import { SCHEMA_NAME_HEADER_OFFSET_TOP } from '../../../common/ui/object-schema-page-layout/page-header';
import { CreateObjectModal } from './create-object-modal';
import { EmptyPageContent } from './empty';
import { ManageBulkEditModals } from './manage-bulk-edit-modals';
import { ManageObjectTypeModals } from './manage-object-type-modals';
import { PageContent } from './page-content';
import { PageHeader } from './page-header';
import { PageTabs } from './page-tabs';
import { SchemaGraphModal } from './schema-graph-modal';

export const LoadedPageContent = () => {
	const { workspaceId } = useWorkspaceContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [{ objectId, objectSchemaId, selectedObjectTypeId }] = useSchemaPageUiState();
	const [{ objectTypesById }] = useLoadedSchemaPageData();
	const [schemaPageData] = useLoadedSchemaPageData();
	const isInherited =
		selectedObjectTypeId !== null &&
		isObjectTypeInherited(schemaPageData, selectedObjectTypeId) &&
		fg('assets_rearch_total_count_deprecated_for_ui');

	if (Object.keys(objectTypesById).length === 0) {
		return (
			<>
				{/* NB: these must always be rendered */}
				<ManageObjectTypeModals />
				<EmptyPageContent />
			</>
		);
	}

	if (selectedObjectTypeId === null || workspaceId === undefined) {
		// This should only every occur for brief moments, if ever, such as when an object type is deleted and the store
		// is still busy re-selecting a new valid one. Render nothing in the meantime.
		return null;
	}

	const renderPageContent = () => (
		<>
			<PageHeader data-testid="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-header-on" />
			<PageTabs data-testid="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-tabs-on" />
			<PageContent data-testid="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-content-on" />
		</>
	);

	// Note: InsightObjectSearchStore is rendered high up in the tree so that:
	// - We fetch the initial list of objects as soon as possible
	// - Search results are retained when switching between objects and attributes view modes
	// - We can show a "no objects" empty state early
	return (
		<InsightObjectSearchStore
			initialSelectedObjectId={objectId}
			searchScope={{
				type: 'schema-object-type',
				objectSchemaId,
				objectTypeId: selectedObjectTypeId,
				isObjectTypeInherited: isInherited,
			}}
			workspaceId={workspaceId}
			createAnalyticsEvent={createAnalyticsEvent}
		>
			<ContentWrapper>
				{/* Remove when cleaning up assets_rearch_async_actions */}
				<ManageBulkEditModals />
				<ManageObjectTypeModals />
				<CreateObjectModal />
				<SchemaGraphModal />
				{renderPageContent()}
				<TrackObjectTypeView id={selectedObjectTypeId} />
			</ContentWrapper>
		</InsightObjectSearchStore>
	);
};
const PAGE_LAYOUT_OFFSET_TOP = 'var(--topNavigationHeight, 0px) + var(--bannerHeight, 0px)';

const ContentWrapper = ({ children }: { children?: ReactNode }) => (
	<Flex
		direction="column"
		gap="space.200"
		xcss={getWillShowNav4() ? navRefreshContentWrapperStyle : contentWrapperStyle}
	>
		{children}
	</Flex>
);
const navRefreshContentWrapperStyle = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	height: `calc(100vh - ${PAGE_LAYOUT_OFFSET_TOP} - ${SCHEMA_NAME_HEADER_OFFSET_TOP}px)`,
});
const contentWrapperStyle = xcss({ height: `calc(100vh - ${PAGE_LAYOUT_OFFSET_TOP})` });
