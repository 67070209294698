import React, { type Ref, forwardRef, useEffect, useRef } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { borderRadius, fontSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { updateObjectFilterExperience } from '@atlassian/jira-servicedesk-insight-experiences';
import { UnstyledInsightIcon } from '@atlassian/jira-servicedesk-insight-icon/src/ui/index.tsx';
import { useSchemaPageUiState } from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services/index.tsx';
import type { SearchResultObject } from '@atlassian/jira-servicedesk-insight-object-search-store/src/common/types/index.tsx';
import { useObjectSearchStoreState } from '@atlassian/jira-servicedesk-insight-object-search-store/src/controllers/store/index.tsx';
import { createObjectDetailUrl } from '@atlassian/jira-servicedesk-insight-urls';

const TooltipTag = forwardRef((props, ref: Ref<HTMLDivElement>) => (
	<StyledTooltipTag ref={ref} {...props} />
));

export type ObjectCardProps = {
	isSelected: boolean;
	object: SearchResultObject;
	onSelect: () => void;
};

export const ObjectCard = ({ object, isSelected, onSelect }: ObjectCardProps) => {
	const selectedElementRef = useRef<unknown>(null);

	const { id, avatar, label } = object;
	const { url48, mediaClientConfig } = avatar;

	useEffect(() => {
		if (isSelected && selectedElementRef.current) {
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			selectedElementRef.current.scrollIntoView?.({ block: 'nearest', behavior: 'smooth' });
		}
	}, [isSelected]);

	/*
	 * This card has 2 modes of interaction
	 * 1. Clicking on a card normally stays in the current schema page, changing the displayed object
	 * 2. Middle-click / open-in-new-tab opens the URL of the anchor, which is the full object details page
	 */
	return (
		// @ts-expect-error - TS2322 - Type 'MutableRefObject<unknown>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'.
		<div ref={selectedElementRef}>
			<Card
				href={createObjectDetailUrl(id)}
				isSelected={isSelected}
				onClick={(event) => {
					if (!event.ctrlKey && !event.metaKey) {
						event.preventDefault();
						onSelect();
					}
				}}
				data-testid={`servicedesk-insight-object-schema-page.ui.page-content.loaded.page-content.object-details.object-cards.card-id-${id}`}
			>
				<UnstyledInsightIcon
					iconUrl={url48}
					size="small"
					label={label}
					mediaClientConfig={mediaClientConfig}
				/>
				{/* @ts-expect-error - TS2322 - Type 'ForwardRefExoticComponent<RefAttributes<unknown>>' is not assignable to type 'keyof IntrinsicElements | ComponentType<AllHTMLAttributes<HTMLElement> & { ref: Ref<HTMLElement>; }> | undefined'. */}
				<Tooltip content={label} tag={TooltipTag}>
					<ObjectName isSelected={isSelected}>{label}</ObjectName>
				</Tooltip>
			</Card>
		</div>
	);
};

export const ObjectCards = ({ objects }: { objects: SearchResultObject[] }) => {
	const [{ objectId: selectedObjectId }, { setObjectId }] = useSchemaPageUiState();
	const [searchStoreState, { triggerSuccessfulObjectFilterExperience }] =
		useObjectSearchStoreState();

	const { filtersUpdated } = searchStoreState;
	useEffect(() => {
		if (filtersUpdated) {
			updateObjectFilterExperience.mark('object-list-rendered');
			triggerSuccessfulObjectFilterExperience();
		}
	}, [filtersUpdated, triggerSuccessfulObjectFilterExperience]);

	return (
		<Container>
			{objects.map((object) => {
				const objectId = object.id;
				const isSelected = objectId === selectedObjectId;
				return (
					<ObjectCard
						key={objectId}
						object={object}
						isSelected={isSelected}
						onSelect={() => {
							setObjectId(objectId, 'push');
						}}
					/>
				);
			})}
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled2.div({
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.050', '4px'),
	padding: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Card = styled2.a<{ isSelected: boolean }>({
	display: 'flex',
	gap: token('space.100', '8px'),
	alignItems: 'center',
	padding: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${fontSize}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderRadius: token('border.radius', `${borderRadius}px`),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isSelected }: { isSelected: boolean }) =>
		isSelected
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.background.selected', colors.B50)
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('elevation.surface.raised', colors.N0),
	boxShadow: token(
		'elevation.shadow.raised',
		'0 1px 1px rgba(9,30,66,0.25),0 0 1px 1px rgba(9,30,66,0.13)',
	),
	'&:hover, &:focus': {
		textDecoration: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const ObjectName = styled.span<{ isSelected: boolean }>((props) => ({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: props.isSelected
		? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			token('color.text.selected', colors.B400)
		: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			token('color.text.subtle', colors.N600),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledTooltipTag = styled2.div({
	display: 'flex',
	minWidth: 0,
});
