import { stringify as qsStringify } from 'query-string';
import type {
	SchemaId,
	WorkspaceId,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type {
	ObjectSchemaAllObjectTypesFlatRequest,
	ObjectSchemaByIdObjectTypesFlatRequest,
	ObjectSchemaByIdObjectTypesRequest,
	ObjectSchemaListRequest,
} from '../types/object-schema';
import { getCmdbRestBaseUrl } from './index';

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/objectschema
 */
const getCmdbObjectSchemaUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbRestBaseUrl(workspaceId)}/objectschema`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/objectschema/${schemaId}
 */
export const getCmdbObjectSchemaByIdUrl = (workspaceId: WorkspaceId, schemaId: SchemaId) =>
	`${getCmdbObjectSchemaUrl(workspaceId)}/${schemaId}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/objectschema/${schemaId}/objecttypes
 */
export const getCmdbObjectSchemaByIdObjectTypesUrl = (
	workspaceId: WorkspaceId,
	schemaId: SchemaId,
	queryParams: ObjectSchemaByIdObjectTypesRequest,
) => `${getCmdbObjectSchemaByIdUrl(workspaceId, schemaId)}/objecttypes?${qsStringify(queryParams)}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/objectschema/${schemaId}/objecttypes/flat
 */
export const getCmdbObjectSchemaByIdObjectTypesFlatUrl = (
	workspaceId: WorkspaceId,
	schemaId: SchemaId,
	queryParams?: ObjectSchemaByIdObjectTypesFlatRequest,
) =>
	`${getCmdbObjectSchemaByIdUrl(workspaceId, schemaId)}/objecttypes/flat${
		queryParams ? `?${qsStringify(queryParams)}` : ''
	}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/objectschema/all/${schemaId}/objecttypes/flat
 */
export const getCmdbObjectSchemaAllObjectTypesFlatUrl = (
	workspaceId: WorkspaceId,
	schemaId: SchemaId,
	queryParams: ObjectSchemaAllObjectTypesFlatRequest,
) =>
	`${getCmdbObjectSchemaUrl(workspaceId)}/all/${schemaId}/objecttypes/flat?${qsStringify(
		queryParams,
	)}`;

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/objectschema/create
 */
export const getCmdbObjectSchemaCreateUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbObjectSchemaUrl(workspaceId)}/create`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/objectschema/create
 */
export const getCmdbObjectSchemaListUrl = (
	workspaceId: WorkspaceId,
	queryParams: ObjectSchemaListRequest,
) => `${getCmdbObjectSchemaUrl(workspaceId)}/list?${qsStringify(queryParams)}`;

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/objectschema/${schemaId}/attributes
 */
export const getCmdbFindAllAttributesUrl = (workspaceId: WorkspaceId, schemaId: SchemaId) =>
	`${getCmdbRestBaseUrl(workspaceId)}/objectschema/${schemaId}/attributes?${qsStringify({ extended: true })}`;
