import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { fireJsonOnlyErrorAnalytics } from '@atlassian/jira-servicedesk-common/src/utils/analytics';
import { STATUS_COMPLETED } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task';
import type { StoreActionApi } from '@atlassian/react-sweet-state/src/types';
import { getCompletedTasks } from '../../../common/utils/get-completed-tasks';
import { mergeTasks } from '../../../common/utils/merge-tasks';
import { getAllTasks } from '../../services/get-all-tasks';
import type { CmdbAsyncActionStoreAction, ContainerProps, State } from '../../types';
import { showCompletedFlags } from '../show-completed-flags';

type PollTasks = {
	consecutiveErrors: number;
	resolve: (value?: unknown) => void;
	reject: (error: Error) => void;
} & StoreActionApi<State> &
	Pick<ContainerProps, 'workspaceId' | 'createAnalyticsEvent'>;

/*
  We have chosen these numbers as it would indicate that the polling has failed
  for 1 minute which is a reasonable time to stop polling.
*/
export const MAX_ERRORS = 6;
export const DELAY = 10000;

export const startTaskPolling =
	(): CmdbAsyncActionStoreAction =>
	async ({ getState, setState, dispatch }, { workspaceId, createAnalyticsEvent }) => {
		try {
			if (getState().isPolling) {
				return;
			}
			setState({ isPolling: true });

			await new Promise((resolve, reject) => {
				pollTasks({
					consecutiveErrors: 0,
					getState,
					setState,
					dispatch,
					workspaceId,
					createAnalyticsEvent,
					resolve,
					reject,
				});
			});
		} catch (error) {
			fireJsonOnlyErrorAnalytics({
				error,
				meta: {
					id: 'consecutivePollTasks',
					packageName: 'jiraServicedeskCmdbAsyncActions',
					teamName: 'falcons',
				},
			});
		} finally {
			setState({ isPolling: false });
		}
	};

export async function pollTasks({
	consecutiveErrors,
	getState,
	setState,
	dispatch,
	workspaceId,
	createAnalyticsEvent,
	resolve,
	reject,
}: PollTasks) {
	try {
		const newTasks = await getAllTasks(workspaceId);
		const newCompletedTasks = getCompletedTasks(getState().tasks, newTasks);
		const mergedTasks = mergeTasks(getState().tasks, newTasks);

		if (newCompletedTasks.length) {
			dispatch(showCompletedFlags(newCompletedTasks));
		}

		setState({
			tasks: mergedTasks,
		});

		fireTrackAnalytics(createAnalyticsEvent({}), 'pollTasks succeeded');

		// Cancel polling if all tasks are completed or not present
		if (mergedTasks.every((task) => task.status === STATUS_COMPLETED)) {
			resolve();
			return;
		}

		setTimeout(
			() =>
				pollTasks({
					consecutiveErrors: 0,
					getState,
					setState,
					dispatch,
					workspaceId,
					createAnalyticsEvent,
					resolve,
					reject,
				}),
			DELAY,
		);
	} catch (error) {
		fireJsonOnlyErrorAnalytics({
			error,
			meta: {
				id: 'pollTasks',
				packageName: 'jiraServicedeskCmdbAsyncActions',
				teamName: 'falcons',
			},
		});

		if (consecutiveErrors + 1 === MAX_ERRORS) {
			reject(new Error('Task polling failed due to consecutive errors'));
			return;
		}

		setTimeout(
			() =>
				pollTasks({
					consecutiveErrors: consecutiveErrors + 1,
					getState,
					setState,
					dispatch,
					workspaceId,
					createAnalyticsEvent,
					resolve,
					reject,
				}),
			DELAY,
		);
	}
}
