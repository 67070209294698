/** @jsx jsx */
import React, { useEffect, useState, type ReactNode } from 'react';
import { css, styled as styled2, jsx } from '@compiled/react';
import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import { xcss, Grid, Box } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line import/order
import Tooltip from '@atlaskit/tooltip';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { getUniqueIconUrl } from '@atlassian/jira-servicedesk-insight-icon/src/common/utils.tsx';
import { UnstyledInsightIcon } from '@atlassian/jira-servicedesk-insight-icon/src/ui/index.tsx';
import type { ObjectType } from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/common/types.tsx';
import { useInsightObjectSchemaPageData } from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services/index.tsx';
import {
	useUpdateQueryParams,
	SCHEMA_PAGE_QUERY_PARAMS,
} from '@atlassian/jira-servicedesk-insight-urls/src/services/index.tsx';
import { SidebarCustomLinkItem } from '../../../../../sidebar-link-item';
import { BadgeAndActions } from './badge-and-actions';

export type Props = {
	isSelected: boolean;
	isExpandable: boolean;
	isExpanded: boolean;
	objectType: ObjectType;
	onExpand: () => void;
	onCollapse: () => void;
	leftIndentation: number;
};

const sidebarCustomLinkCss = {
	paddingInline: token('space.100', `${gridSize}px`),
	paddingBlock: token('space.0', '0'),
	'&:focus-visible': { outline: '0 !important' },
	'&:focus': { outline: '0 !important' },
} as const;

const sidebarCustomLinkCssActive = {
	...sidebarCustomLinkCss,
	backgroundColor: token('color.background.selected', colors.DN900),
	position: 'relative',
	'&:before': {
		content: '',
		position: 'absolute',
		left: 0,
		borderRadius: '0 3px 3px 0',
		// Disabled for pixel perfect alignment

		top: '50%',
		transform: 'translateY(-50%)',
		width: '4px',
		height: '20px',
		backgroundColor: token('color.background.brand.bold', colors.B400),
		display: 'block',
	},
} as const;

export const ObjectTypeLink = ({
	objectType,
	isSelected,
	isExpandable,
	isExpanded,
	onExpand,
	onCollapse,
	leftIndentation,
}: Props) => {
	const [isHovering, setIsHovering] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	const [{ updateQueryParams }] = useUpdateQueryParams();
	const [{ currentOTMeatballMenu }, { setCurrentOTMeatballMenu }] =
		useInsightObjectSchemaPageData();

	const onChangeMenuOpen = (newIsOpen: boolean) => {
		setCurrentOTMeatballMenu(newIsOpen ? objectType.id : null);
	};

	// Ensure hover and focus states are cleared in case of creating or copying
	// an object type resulting in the mouseleave event not being fired
	useEffect(() => {
		if (currentOTMeatballMenu !== objectType.id) {
			setIsHovering(false);
			setIsFocused(false);
		}
	}, [currentOTMeatballMenu, objectType.id]);

	const { id, name, icon } = objectType;
	const getDotIcon = () => (isSelected ? <ActiveDot /> : <Dot />);
	const expandToggle = isExpandable ? (
		<Button
			onClick={(e) => {
				// Prevent clicks from navigating to the object type
				e.preventDefault();
				e.stopPropagation();
				if (isExpanded) {
					onCollapse();
				} else {
					onExpand();
				}
			}}
			appearance="subtle"
			iconBefore={
				isExpanded ? (
					// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
					<ChevronDownIcon
						label=""
						primaryColor={
							isSelected ? token('color.background.brand.bold', colors.B400) : 'inherit'
						}
					/>
				) : (
					// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
					<ChevronRightIcon
						label=""
						primaryColor={
							isSelected ? token('color.background.brand.bold', colors.B400) : 'inherit'
						}
					/>
				)
			}
		/>
	) : (
		getDotIcon()
	);

	const pathToObjectType = updateQueryParams({
		[SCHEMA_PAGE_QUERY_PARAMS.objectTypeId]: id,
		[SCHEMA_PAGE_QUERY_PARAMS.objectId]: null,
	});

	return (
		<SidebarCustomLinkItem
			to={pathToObjectType}
			isSelected={isSelected}
			// @ts-expect-error - TS2322 - Type '{ children: Element; to: string; isSelected: boolean; onMouseOver: () => void; onMouseLeave: () => void; onFocus: () => void; onBlur: () => void; testId: string; }' is not assignable to type 'IntrinsicAttributes & { to: string; } & CustomItemProps<CustomItemComponentProps>'.
			onMouseOver={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
			onFocus={() => setIsFocused(true)}
			onBlur={() => setIsFocused(false)}
			aria-current={isSelected ? 'page' : false}
			css={() =>
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
				isSelected ? css({ ...sidebarCustomLinkCssActive }) : css({ ...sidebarCustomLinkCss })
			}
			testId="servicedesk-insight-object-schema-page.common.ui.side-navigation.loaded.content.object-types.object-type-link"
		>
			<Tooltip content={name}>
				<SidebarCustomLinkItemInnerWrapper
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					css={css({
						// Disabled since padding is set by dragging

						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
						paddingLeft: `${leftIndentation}px`,
					})}
				>
					<Container>
						<ExpandContainer>{expandToggle}</ExpandContainer>
						<UnstyledInsightIcon label={icon.name} iconUrl={getUniqueIconUrl(icon)} size="small" />
						{isHovering || isFocused ? <NameActive>{name}</NameActive> : <Name>{name}</Name>}
						<BadgeAndActions
							objectType={objectType}
							isHovering={isHovering}
							isFocussed={isFocused}
							isSelected={isSelected}
							isMenuOpen={currentOTMeatballMenu === objectType.id}
							onChangeMenuOpen={onChangeMenuOpen}
						/>
					</Container>
				</SidebarCustomLinkItemInnerWrapper>
			</Tooltip>
		</SidebarCustomLinkItem>
	);
};

const Container = ({ children }: { children?: ReactNode }) => (
	<Grid xcss={containerStyles}>{children}</Grid>
);

const Dot = () => <Box xcss={dotStyles} />;

const ActiveDot = () => <Box xcss={activeDotStyles} />;

const Name = ({ children }: { children?: ReactNode }) => (
	<Box as="span" xcss={nameStyles}>
		{children}
	</Box>
);

const NameActive = ({ children }: { children?: ReactNode }) => (
	<Box as="span" xcss={nameActiveStyles}>
		{children}
	</Box>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExpandContainer = styled2.div({
	justifySelf: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SidebarCustomLinkItemInnerWrapper = styled2.div({
	width: 'auto',
});

const activeDotStyles = xcss({
	width: '4px',
	height: '4px',
	borderRadius: 'border.radius.circle',
	backgroundColor: 'color.background.brand.bold',
});

const nameStyles = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const nameActiveStyles = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	width: 'calc(100% - 52px)',
});

const containerStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	gridTemplateColumns: `${3 * gridSize}px auto 1fr auto`,
	gap: 'space.100',
	alignItems: 'center',
	padding: 'space.025',
});

const dotStyles = xcss({
	width: '4px',
	height: '4px',
	borderRadius: 'border.radius.circle',
	backgroundColor: 'color.background.neutral.bold',
});
