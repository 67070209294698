import React from 'react';
import { styled } from '@compiled/react';
import Spinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';
// eslint-disable-next-line import/order
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	SCREEN,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { JsmPageInteractiveEvent } from '@atlassian/jira-servicedesk-common/src/utils/browser-metric/index.tsx';
import { loadSchemaObjectListExperience } from '@atlassian/jira-servicedesk-insight-experiences';
import {
	type Column,
	type SearchStateValue,
	matchSearchState,
} from '@atlassian/jira-servicedesk-insight-object-search-store/src/common/types/index.tsx';
import {
	useObjectSearchStoreState,
	useObjectSearchPagination,
} from '@atlassian/jira-servicedesk-insight-object-search-store/src/controllers/store/index.tsx';
import {
	getColumns,
	getPendingSearchType,
} from '@atlassian/jira-servicedesk-insight-object-search-store/src/services/selectors/index.tsx';
import { InsightObjectSchemaListBodyOnlySkeleton } from '@atlassian/jira-skeletons/src/ui/insight-object-schema/index.tsx';
import { ExperienceSuccess } from '@atlassian/ufo';
import { LoadObjectsError } from '../../../../../common/ui/load-objects-error';
import { NoFilterResults } from '../../../../../common/ui/no-filter-results';
import { ObjectListSkeleton } from '../../../../../common/ui/object-list-skeleton';
import { ObjectsPaginationLabel } from '../../../../../common/ui/objects-pagination-label';
import { TotalObjectsPagination } from '../../../../../common/ui/total-objects-pagination';
import { Controls } from './controls';
import { messages } from './messages';
import { PaginationFooter } from './pagination-footer';
import { ObjectTable } from './table';

// Export with this name for tests / storybooks where we want to blow through the whole stack on errors
export const ObjectListWithoutErrorBoundary = () => {
	const { formatMessage } = useIntl();
	const [searchStoreState] = useObjectSearchStoreState();
	const [{ numPages }] = useObjectSearchPagination();
	const { lastSearch } = searchStoreState;

	return matchSearchState(lastSearch, {
		loading: () => <ObjectListSkeleton startExperience />,
		error: (error) => <LoadObjectsError error={error} />,
		success: ({ result }: SearchStateValue) => {
			const { objects } = result;
			const columns: Column[] = getColumns(searchStoreState);
			const pendingSearchType = getPendingSearchType(searchStoreState);

			const RenderPagination = () =>
				fg('assets_rearch_total_count_deprecated_for_ui') ? (
					<TotalObjectsPagination />
				) : (
					<ObjectsPaginationLabel />
				);

			return (
				<ContextualAnalyticsData sourceType={SCREEN} sourceName="cmdbObjectList">
					<ContainerObjectList>
						<FireScreenAnalytics />
						<JsmPageInteractiveEvent
							extra={{ schemaViewMode: 'object', eventMonitorMode: 'list' }}
						/>
						<ExperienceSuccess experience={loadSchemaObjectListExperience} />
						<Header>
							<ListInfoContainer>
								{numPages === 1 && pendingSearchType === null && RenderPagination()}
								{pendingSearchType === 'refresh' && (
									<>
										{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
										<span>{formatMessage(messages.loading)}</span>
										<Spinner size="small" />
									</>
								)}
							</ListInfoContainer>
							<Controls />
						</Header>
						{objects.length > 0 ? (
							<>
								{pendingSearchType === 'search' ? (
									<InsightObjectSchemaListBodyOnlySkeleton />
								) : (
									<ObjectTable searchResult={result} columns={columns} />
								)}
								{numPages > 1 && <PaginationFooter />}
							</>
						) : (
							<>
								<EmptyObjectTableWrapper>
									{pendingSearchType === 'search' ? (
										<InsightObjectSchemaListBodyOnlySkeleton />
									) : (
										<ObjectTable searchResult={result} columns={columns} />
									)}
								</EmptyObjectTableWrapper>
								<NoFilterResults imageWidth="large" showText />
							</>
						)}
					</ContainerObjectList>
				</ContextualAnalyticsData>
			);
		},
	});
};

export const ObjectList = () => (
	<JSErrorBoundary
		id="objectList"
		packageName="jiraServicedeskInsightObjectSchemaPage"
		fallback="flag"
	>
		<ObjectListWithoutErrorBoundary />
	</JSErrorBoundary>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContainerObjectList = styled(Container)({
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ListInfoContainer = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N200),
	display: 'flex',
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyObjectTableWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 5}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Header = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});
