import React, { type ReactNode, useState, useMemo, type ReactElement } from 'react';
import throttle from 'lodash/throttle';
import { Box, Flex, xcss } from '@atlaskit/primitives';
// eslint-disable-next-line import/order
import { WidthObserver } from '@atlaskit/width-detector';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

export type InsightObjectViewLayoutProps = {
	renderHeader: (isMultiColumn: boolean) => ReactElement;
	renderPrimary: (isMultiColumn: boolean) => ReactElement;
	renderSecondary: (isCompact: boolean) => ReactElement;
};

export const InsightObjectViewLayout = (props: InsightObjectViewLayoutProps) => {
	const { renderHeader, renderPrimary, renderSecondary } = props;
	const [width, setWidth] = useState(MIN_COLUMN_WIDTH_FOR_MULTI_COLUMN_PRIMARY);
	const throttledSetWidth = useMemo(() => throttle(setWidth, 250), []);
	const primaryAndSecondaryAreMultiColumn = width >= MIN_MULTI_COLUMN_WIDTH;
	const isPrimaryContentMultiColumn =
		width >= MIN_COLUMN_WIDTH_FOR_MULTI_COLUMN_PRIMARY || width < MIN_MULTI_COLUMN_WIDTH;
	const isSecondaryContentCompact =
		width <= MIN_COLUMN_WIDTH_FOR_COMPACT_SECONDARY && primaryAndSecondaryAreMultiColumn;

	return (
		<Wrapper>
			{renderHeader(primaryAndSecondaryAreMultiColumn)}
			<GridWrapper isMultiColumn={primaryAndSecondaryAreMultiColumn}>
				<PrimaryContent isMultiColumn={primaryAndSecondaryAreMultiColumn}>
					{renderPrimary(isPrimaryContentMultiColumn)}
				</PrimaryContent>
				<SecondaryContent isMultiColumn={primaryAndSecondaryAreMultiColumn}>
					{renderSecondary(isSecondaryContentCompact)}
				</SecondaryContent>
			</GridWrapper>
			<WidthObserver setWidth={throttledSetWidth} offscreen />
		</Wrapper>
	);
};

// The minimum width required before displaying content in columns
const MIN_MULTI_COLUMN_WIDTH = 584;

// The minimum width required before displaying the primary content in columns
const MIN_COLUMN_WIDTH_FOR_MULTI_COLUMN_PRIMARY = 664;

// The minimum column width before displaying the secondary content in compact mode
const MIN_COLUMN_WIDTH_FOR_COMPACT_SECONDARY = 824;

// Space between columns
export const COLUMN_GUTTERS = gridSize * 5;

// Multipliers used to determine minimum column widths
const PRIMARY_CONTENT_PROPORTION = 7;
const SECONDARY_CONTENT_PROPORTION = 5;

// Calculated widths based on proportions above
const COLUMN_COUNT = PRIMARY_CONTENT_PROPORTION + SECONDARY_CONTENT_PROPORTION;
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const PRIMARY_CONTENT_WIDTH = `${(PRIMARY_CONTENT_PROPORTION / COLUMN_COUNT) * 100}%`;
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const SECONDARY_CONTENT_WIDTH = `${(SECONDARY_CONTENT_PROPORTION / COLUMN_COUNT) * 100}%`;

const GridWrapper = ({
	isMultiColumn,
	children,
}: {
	isMultiColumn: boolean;
	children?: ReactNode;
}) => (
	<Flex
		direction={isMultiColumn ? 'row' : 'column'}
		gap="space.500"
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		xcss={xcss({
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			height: isMultiColumn ? '100%' : undefined,
		})}
	>
		{children}
	</Flex>
);

const Wrapper = ({ children }: { children?: ReactNode }) => (
	<Flex
		direction="column"
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		xcss={xcss({
			position: 'relative',
			overflowX: 'auto',
			marginBottom: 'space.600',
			minWidth: '355px',
		})}
	>
		{children}
	</Flex>
);

const PrimaryContent = ({
	isMultiColumn,
	children,
}: {
	isMultiColumn: boolean;
	children?: ReactNode;
}) => (
	<Box
		xcss={[
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			xcss({
				width: '100%',
			}),
			isMultiColumn &&
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
				xcss({
					// need to account for the COLUMN_GUTTER in max width to avoid pushing
					// the secondary content out of the container
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
					maxWidth: `calc(${PRIMARY_CONTENT_WIDTH} - ${COLUMN_GUTTERS}px)`,
				}),
		]}
	>
		{children}
	</Box>
);

const SecondaryContent = ({
	isMultiColumn,
	children,
}: {
	isMultiColumn: boolean;
	children?: ReactNode;
}) => (
	<Box
		// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		style={
			isMultiColumn
				? {
						/* Offset the secondary column by the height of atlaskit tabs */
						paddingTop: '33px',
					}
				: {}
		}
		xcss={[
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			xcss({
				width: '100%',
			}),
			isMultiColumn &&
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
				xcss({
					maxWidth: SECONDARY_CONTENT_WIDTH,
				}),
		]}
	>
		{children}
	</Box>
);
