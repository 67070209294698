import React, { type ReactNode } from 'react';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { AsyncCopyObjectTypeModal } from '@atlassian/jira-servicedesk-insight-copy-object-type-modal/src/async.tsx';
import { AsyncCreateObjectTypeModal } from '@atlassian/jira-servicedesk-insight-create-object-type-modal/src/async.tsx';
import type { ObjectType as StoreObjectType } from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/common/types.tsx';
import {
	useInsightObjectSchemaPageData,
	useLoadedSchemaPageData,
} from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services/index.tsx';
import { objectTypesToArray } from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services/selectors/index.tsx';
import { Role } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/insight-roles.tsx';
import type { ObjectType as CommonObjectType } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/object-type.tsx';
import { toCmdbObjectTypeId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';

const convertToStoreObjectType = ({
	id,
	name,
	icon,
	position,
	parentObjectTypeId,
	objectCount,
	abstractObjectType,
	parentObjectTypeInherited,
	inherited,
}: CommonObjectType): StoreObjectType => ({
	id: toCmdbObjectTypeId(id),
	name,
	icon,
	position,
	parentObjectTypeId: parentObjectTypeId !== undefined ? parentObjectTypeId : null,
	abstractObjectType,
	parentObjectTypeInherited,
	inherited,
	objectCount,
	currentUserRole: Role.MANAGER,
});

export const ManageObjectTypeModals = () => {
	const [{ objectTypeModalState }, { setObjectTypeModalState, addCreatedObjectType }] =
		useInsightObjectSchemaPageData();
	const [{ objectSchema, schemaGlobalConfig, objectTypesById }] = useLoadedSchemaPageData();
	const { icons } = schemaGlobalConfig;
	/**
	 * These modals would be rendered from the sidebar, but has to be rendered in the content due to an Atlaskit bug:
	 * https://product-fabric.atlassian.net/browse/DSP-1821
	 */
	let modal: ReactNode = null;

	switch (objectTypeModalState.type) {
		case 'creating': {
			const { initialParent } = objectTypeModalState;
			modal = (
				<AsyncCreateObjectTypeModal
					objectSchemaId={objectSchema.id}
					schemaIcons={icons}
					schemaObjectTypes={objectTypesToArray(objectTypesById).map(({ id, name, icon }) => ({
						id,
						name,
						icon,
					}))}
					initialParentObjectTypeId={initialParent}
					onClose={() => {
						setObjectTypeModalState({ type: 'idle' });
					}}
					onCreate={(newObjectType) => {
						addCreatedObjectType(convertToStoreObjectType(newObjectType));
					}}
				/>
			);
			break;
		}
		case 'copying': {
			const { objectTypeIdToCopy } = objectTypeModalState;
			modal = (
				<AsyncCopyObjectTypeModal
					objectTypeToCopy={{
						id: objectTypeIdToCopy,
						name: objectTypesById[objectTypeIdToCopy].name,
					}}
					onClose={() => {
						setObjectTypeModalState({ type: 'idle' });
					}}
					onCreate={(newObjectType) => {
						addCreatedObjectType(convertToStoreObjectType(newObjectType));
						setObjectTypeModalState({ type: 'idle' });
					}}
				/>
			);
			break;
		}
		case 'idle': {
			break;
		}
		default: {
			// prettier-ignore
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(objectTypeModalState as never);
			break;
		}
	}

	return <ModalTransition>{modal}</ModalTransition>;
};
