import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	fetchSchemaStatusTypes,
	type StatusTypeResponse,
} from '@atlassian/jira-servicedesk-insight-object-schema-services/src/services/get-schema-status-types/index.tsx';
import type {
	SchemaId,
	WorkspaceId,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import type { AttributeFiltersByName } from '../../../types';
import type { ValueMatch } from '../../../types/filters';

/**
 * What is this?!
 * Unfortunately the /navlist endpoint returns status names for status filters, however, requests to /navlist need to be
 * in terms of status ID's. Names are replaced with ID's in this layer to prevent this inconsistency spreading
 * throughout the front-end.
 */
export const replaceFilterStatusNamesWithIds = async ({
	workspaceId,
	schemaId,
	filters,
}: {
	workspaceId: WorkspaceId;
	schemaId: SchemaId;
	filters: AttributeFiltersByName;
}): Promise<AttributeFiltersByName> => {
	try {
		const updatedFilters: AttributeFiltersByName = {};
		let loadedStatuses: StatusTypeResponse[] | null = null;

		for (let filterIndex = 0; filterIndex < Object.keys(filters).length; filterIndex += 1) {
			const attributeName = Object.keys(filters)[filterIndex];
			const filter = filters[attributeName];
			const { attributeFilter } = filter;

			if (attributeFilter.type !== 'status') {
				updatedFilters[attributeName] = filter;
				// eslint-disable-next-line no-continue
				continue;
			}

			if (loadedStatuses === null) {
				// Will be called at most once for the entire function
				// eslint-disable-next-line no-await-in-loop
				loadedStatuses = await fetchSchemaStatusTypes({ workspaceId, schemaId });
			}

			const updatedStatusValueMatches: ValueMatch<string>[] = [];
			for (let valueIndex = 0; valueIndex < attributeFilter.values.length; valueIndex += 1) {
				const valueMatch = attributeFilter.values[valueIndex];
				if (valueMatch.type === 'wildcard') {
					updatedStatusValueMatches.push(valueMatch);
				} else {
					const statusName = valueMatch.value;
					const matchingStatus = loadedStatuses.find(
						(status) => status.name.toLowerCase() === statusName.toLowerCase(),
					);
					if (matchingStatus != null) {
						updatedStatusValueMatches.push({
							type: 'exact-match',
							value: matchingStatus.id,
						});
					}
				}
			}
			updatedFilters[attributeName] = {
				...filter,
				attributeFilter: {
					type: 'status',
					values: updatedStatusValueMatches,
				},
			};
		}

		return updatedFilters;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			meta: {
				id: 'replaceFilterStatusNamesWithIds',
				packageName: 'jiraServicedeskInsightObjectSearchStore',
				teamName: 'falcons',
			},
			error,
		});
		return filters;
	}
};
