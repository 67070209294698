import type { CmdbObjectType, CmdbReferenceType } from './cmdb-object';
import type { ObjectTypeAttributeId } from './shared-types/index.tsx';

/**
 * Each attribute type returns a different shaped value
 * Default:
 *   - Attribute details passed in objectAttributeValues, can probably just use displayValue
 *   - More specific attribute type (e.g. float, boolean, text) is passed in objectTypeAttribute.defaultType (id, name)
 *     Use DefaultAttributeSubtypeMap below to determine specific type
 * Object:
 *   - Attribute details passed in a few areas...
 *     -- objectTypeAttribute.referenceType includes metadata about the link between the objects, e.g if its a
 *        "dependency", or a "link", or "works at", or whatever.. like how Jira link issue has multiple ways to link issues
 *     -- objectTypeAttribute.referenceObjectType has details about the object type of the referenced object
 *     -- objectAttributeValues contains the actual info about the linked objects. displayValue will contain the object label,
 *        whilst referencedObject will contain all the other info about the object
 * User:
 *   - objectAttributeValues contains the details about users
 *     -- displayValue contains the users full name and username
 *     -- user contains more info about the user (avatarUrl, renderedLink, etc)
 * Group:
 *   - objectAttributeValues contains the details about groups
 *     -- displayValue contains the group name
 *     -- group contains the name and avatarUrl for the group
 * Project:
 *  - objectAttributeValues contains the details about projects
 *     -- displayValue contains project name
 *     -- project contains ari, avatarUrl, id, key, name and url for the project
 * Bitbucket Repo:
 *  - objectAttributeValues contains the details about bitbucket repos
 *     -- displayValue contains bitbucket repo name
 *     -- bitbucket repos contains id, name, url, and avatarUrl for the repository
 * Status:
 *   - objectAttributeValues contains the details about groups
 *     -- displayValue contains the status name
 *     -- 'status' contains id, name, and category of the status (category determines which colour the status is)
 */

/**
 * This map represents the different attribute types and their corresponding `AttributeType.type` property values
 * We will use this to determine which properties are available within the attribute values
 */
export const AttributeTypeMap = {
	Default: 0,
	Object: 1,
	User: 2,
	Group: 4,
	Project: 6,
	Status: 7,
	BitbucketRepo: 8,
	OpsgenieTeam: 9,
} as const;

/**
 * This map represents the different attribute sub-types within the 'Default' attribute type.
 * We will use this type to determine which attribute renderer to use to render the displayValue.
 */
export const DefaultAttributeSubtypeMap = {
	Text: '0',
	Integer: '1',
	Boolean: '2',
	Double: '3',
	Date: '4',
	Time: '5',
	DateTime: '6',
	URL: '7',
	Email: '8',
	Textarea: '9',
	Select: '10',
	IPAddress: '11',
} as const;

type CmdbObjectTypeAttributeBase = {
	id: ObjectTypeAttributeId;
	name?: string;
	label: boolean;
	position: number;
};

type CmdbDefaultType = {
	/** The id and name properties for this type will match the map above */
	id: string;
	name: string;
};

export type CmdbObjectTypeAttributeDefault = CmdbObjectTypeAttributeBase & {
	type: 0;
	defaultType: CmdbDefaultType; // Only present if attribute type is Default. Contains details of attribute subtype
};
export type CmdbObjectTypeAttributeObject = CmdbObjectTypeAttributeBase & {
	type: 1;
	// referenceType and referenceObjectType will be missing in Object attributes to display on issue view
	referenceType?: CmdbReferenceType; // Contains metadata about the link between the objects,
	referenceObjectType?: CmdbObjectType; // Contains details of linked object's objectType
};

export type CmdbObjectTypeAttributeUser = CmdbObjectTypeAttributeBase & {
	type: 2;
};

export type CmdbObjectTypeAttributeGroup = CmdbObjectTypeAttributeBase & {
	type: 4;
};

export type CmdbObjectTypeAttributeProject = CmdbObjectTypeAttributeBase & {
	type: 6;
};

export type CmdbObjectTypeAttributeStatus = CmdbObjectTypeAttributeBase & {
	type: 7;
};

export type CmdbObjectTypeAttributeBitbucketRepo = CmdbObjectTypeAttributeBase & {
	type: 8;
};

export type CmdbObjectTypeAttributeOpsgenieTeam = CmdbObjectTypeAttributeBase & {
	type: 9;
};

export type ObjectAttributeType =
	| CmdbObjectTypeAttributeDefault
	| CmdbObjectTypeAttributeObject
	| CmdbObjectTypeAttributeUser
	| CmdbObjectTypeAttributeGroup
	| CmdbObjectTypeAttributeStatus
	| CmdbObjectTypeAttributeBitbucketRepo
	| CmdbObjectTypeAttributeOpsgenieTeam;
