import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	matchAttributeType,
	matchDefaultAttributeSubType,
} from '@atlassian/jira-servicedesk-insight-object-types/src/common/utils/type-matchers/index.tsx';
import { toCmdbObjectId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import { toAccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { AttributeFiltersByName, SearchResultObjectTypeAttribute } from '../../types';
import type {
	AttributeFilter,
	DateFilterValue,
	DatePeriodType,
	DateTimePeriodType,
	ValueMatch,
} from '../../types/filters';
import type { FilterRequestItem } from '../../types/requests';

// See ObjectFilterValuesBean for the definition of these constants on the back-end
const WILDCARD_MARKER = '<<FILTER_WILDCARD>>';
// Date filter types
const DATE_FILTER_WITHIN_LAST_AGO = '1';
const DATE_FILTER_MORE_THAN_AGO = '2';
const DATE_FILTER_BETWEEN = '3';
const DATE_FILTER_WITHIN_FROMNOW = '4';
const DATE_FILTER_MORE_THAN_FROMNOW = '5';
// Number filter types
const TYPE_NUMBER_EQUALS = '0';
const TYPE_NUMBER_LESS = '1';
const TYPE_NUMBER_GREATER = '2';
const TYPE_NUMBER_BETWEEN = '3';

const parseNumberFilter = (selectedValues: string[]): AttributeFilter => {
	const [filterType, firstValue, secondValue] = selectedValues;
	switch (filterType) {
		case TYPE_NUMBER_EQUALS:
			return {
				type: 'number',
				value: {
					type: 'equals',
					value: firstValue,
				},
			};
		case TYPE_NUMBER_LESS:
			return {
				type: 'number',
				value: {
					type: 'less-than',
					value: firstValue,
				},
			};
		case TYPE_NUMBER_GREATER:
			return {
				type: 'number',
				value: {
					type: 'greater-than',
					value: firstValue,
				},
			};
		case TYPE_NUMBER_BETWEEN:
			return {
				type: 'number',
				value: {
					type: 'between',
					min: firstValue,
					max: secondValue,
				},
			};
		default: {
			throw new Error(`Unexpected filterType: ${filterType} for number attribute`);
		}
	}
};

const asPeriodType = <PeriodType extends DatePeriodType | DateTimePeriodType>(
	value: string, // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
): PeriodType => value as PeriodType;

const parseDateFilterValue = <PeriodType extends DatePeriodType | DateTimePeriodType>(
	selectedValues: string[],
): DateFilterValue<PeriodType> => {
	const [filterType, firstValue, secondValue] = selectedValues;
	switch (filterType) {
		case DATE_FILTER_WITHIN_LAST_AGO:
			return {
				type: 'within-the-last',
				value: firstValue,
				periodType: asPeriodType<PeriodType>(secondValue),
			};
		case DATE_FILTER_MORE_THAN_AGO:
			return {
				type: 'more-than-ago',
				value: firstValue,
				periodType: asPeriodType<PeriodType>(secondValue),
			};
		case DATE_FILTER_BETWEEN:
			return {
				type: 'between',
				start: firstValue,
				end: secondValue,
			};
		case DATE_FILTER_WITHIN_FROMNOW:
			return {
				type: 'within-from-now',
				value: firstValue,
				periodType: asPeriodType<PeriodType>(secondValue),
			};
		case DATE_FILTER_MORE_THAN_FROMNOW:
			return {
				type: 'more-than-from-now',
				value: firstValue,
				periodType: asPeriodType<PeriodType>(secondValue),
			};
		default: {
			throw new Error(`Unexpected filterType: ${filterType} for date / date-time attribute`);
		}
	}
};

export const extractWildcard = (
	value: string,
): {
	value: string;
	isWildcard: boolean;
} => {
	if (value.endsWith(WILDCARD_MARKER)) {
		return {
			value: value.replace(WILDCARD_MARKER, ''),
			isWildcard: true,
		};
	}
	return {
		value,
		isWildcard: false,
	};
};

export const filterFromResponse = ({
	objectTypeAttribute,
	selectedValues,
}: {
	objectTypeAttribute: SearchResultObjectTypeAttribute;
	selectedValues: string[];
}): AttributeFilter => {
	try {
		const extractStringValueMatches = () =>
			selectedValues.map<ValueMatch<string>>((rawValue) => {
				const { value, isWildcard } = extractWildcard(rawValue);
				if (isWildcard) {
					return {
						type: 'wildcard',
						value,
					};
				}
				return {
					type: 'exact-match',
					value,
				};
			});

		return matchAttributeType<AttributeFilter>(objectTypeAttribute.attributeType, {
			default: () => {
				if (objectTypeAttribute.defaultType === undefined) {
					throw new Error('Unexpected defaultType missing from default object type attribute');
				}

				const handleTextFilter = (): AttributeFilter => ({
					type: 'text',
					values: extractStringValueMatches(),
				});
				const handleNumberFilter = () => parseNumberFilter(selectedValues);
				return matchDefaultAttributeSubType<AttributeFilter>(objectTypeAttribute.defaultType, {
					url: handleTextFilter,
					email: handleTextFilter,
					float: handleNumberFilter,
					integer: handleNumberFilter,
					ipAddress: handleTextFilter,
					text: handleTextFilter,
					textarea: () => ({
						type: 'textarea',
						// The UI only supports matching on one value in basic mode
						value: (selectedValues[0] || '').replace(WILDCARD_MARKER, ''),
					}),
					select: handleTextFilter,
					boolean: () => {
						const [rawValue] = selectedValues;
						return {
							type: 'boolean',
							value: rawValue === '1' || rawValue.toLowerCase() === 'true',
						};
					},
					date: () => ({
						type: 'date',
						value: parseDateFilterValue<DatePeriodType>(selectedValues),
					}),
					datetime: () => ({
						type: 'date-time',
						value: parseDateFilterValue<DateTimePeriodType>(selectedValues),
					}),
				});
			},
			object: () => ({
				type: 'object',
				values: selectedValues.map((rawValue) => {
					const { value, isWildcard } = extractWildcard(rawValue);
					if (isWildcard) {
						return {
							type: 'wildcard',
							value,
						};
					}
					return {
						type: 'exact-match',
						value: toCmdbObjectId(value),
					};
				}),
			}),
			user: () => ({
				type: 'user',
				values: selectedValues.map((rawValue) => {
					const { value, isWildcard } = extractWildcard(rawValue);
					if (isWildcard) {
						return {
							type: 'wildcard',
							value,
						};
					}
					return {
						type: 'exact-match',
						value: toAccountId(value),
					};
				}),
			}),
			group: () => ({
				type: 'group',
				values: extractStringValueMatches(),
			}),
			project: () => ({
				type: 'project',
				values: extractStringValueMatches(),
			}),
			status: () => ({
				type: 'status',
				values: extractStringValueMatches(),
			}),
			bitbucketRepo: () => ({
				type: 'bitbucketRepo',
				values: extractStringValueMatches(),
			}),
			opsgenieTeam: () => ({
				type: 'opsgenieTeam',
				values: extractStringValueMatches(),
			}),
		});
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			error,
			meta: {
				id: 'filterFromResponse',
				packageName: 'jiraServicedeskInsightObjectSearchStore',
			},
			attributes: {
				message: error.message,
			},
		});
		throw error;
	}
};

export const filterToRequestSelectedValues = (attributeFilter: AttributeFilter): string[] => {
	switch (attributeFilter.type) {
		case 'boolean': {
			return [attributeFilter.value ? '1' : '0'];
		}
		case 'number': {
			const { value } = attributeFilter;
			switch (value.type) {
				case 'equals': {
					return [TYPE_NUMBER_EQUALS, String(value.value)];
				}
				case 'less-than': {
					return [TYPE_NUMBER_LESS, String(value.value)];
				}
				case 'greater-than': {
					return [TYPE_NUMBER_GREATER, String(value.value)];
				}
				case 'between': {
					const minFloat = parseFloat(value.min);
					const maxFloat = parseFloat(value.max);
					const actualMin = Math.min(minFloat, maxFloat);
					const actualMax = Math.max(minFloat, maxFloat);
					return [TYPE_NUMBER_BETWEEN, String(actualMin), String(actualMax)];
				}
				default: {
					// prettier-ignore
					// @ts-expect-error - TS2339 - Property 'type' does not exist on type 'never'.
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					(value.type as never);
					throw new Error(`Unhandled number value: ${JSON.stringify(value)}`);
				}
			}
		}
		case 'date-time':
		case 'date': {
			const { value } = attributeFilter;
			switch (value.type) {
				case 'within-the-last': {
					return [DATE_FILTER_WITHIN_LAST_AGO, String(value.value), value.periodType];
				}
				case 'more-than-ago': {
					return [DATE_FILTER_MORE_THAN_AGO, String(value.value), value.periodType];
				}
				case 'more-than-from-now': {
					return [DATE_FILTER_MORE_THAN_FROMNOW, String(value.value), value.periodType];
				}
				case 'within-from-now': {
					return [DATE_FILTER_WITHIN_FROMNOW, String(value.value), value.periodType];
				}
				case 'between': {
					const { start, end } = value;
					return [DATE_FILTER_BETWEEN, start, end];
				}
				default: {
					// prettier-ignore
					// @ts-expect-error - TS2339 - Property 'type' does not exist on type 'never'.
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					(value.type as never);
					throw new Error(`Unhandled date value: ${JSON.stringify(value)}`);
				}
			}
		}
		case 'textarea': {
			return [`${attributeFilter.value}${WILDCARD_MARKER}`];
		}
		case 'text':
		case 'object':
		case 'user':
		case 'group':
		case 'project':
		case 'bitbucketRepo':
		case 'status':
		case 'opsgenieTeam':
		case 'objectType':
			return attributeFilter.values.map((valueMatch) => {
				if (valueMatch.type === 'wildcard') {
					return `${valueMatch.value}${WILDCARD_MARKER}`;
				}
				return String(valueMatch.value);
			});

		default: {
			// The following line will show a ts error when the switch statement
			// is non-exhaustive. If this happens, add the unhandled case
			// explicitly.
			const assertAttributeFilterIsNever: never = attributeFilter;
			throw new Error(`Unhandled attributeFilter: ${JSON.stringify(assertAttributeFilterIsNever)}`);
		}
	}
};

export const createFilterRequestItems = (
	attributeFiltersByName: AttributeFiltersByName,
	objectTypeFilters: ValueMatch<string>[] = [],
): FilterRequestItem[] => {
	const filterRequestItems = Object.keys(attributeFiltersByName).map<FilterRequestItem>(
		(attributeName) => {
			const { attributeFilter, objectTypeAttributeId } = attributeFiltersByName[attributeName];
			const selectedValues = filterToRequestSelectedValues(attributeFilter);
			return {
				selectedValues,
				objectTypeAttributeId,
			};
		},
	);

	if (objectTypeFilters.length) {
		const selectedObjectValues = filterToRequestSelectedValues({
			type: 'objectType',
			values: objectTypeFilters,
		});
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		filterRequestItems.push({
			selectedValues: selectedObjectValues,
			filterByObjectType: true,
		} as FilterRequestItem);
	}

	return filterRequestItems;
};
