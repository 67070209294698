import React from 'react';
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import Heading from '@atlaskit/heading';
import { Box, xcss } from '@atlaskit/primitives';
import Skeleton from '@atlaskit/skeleton';
import PageHeader from '@atlassian/jira-common-components-page-header';
import { useIntl } from '@atlassian/jira-intl';
import {
	useLoadedSchemaPageData,
	useInsightObjectSchemaPageData,
} from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services/index.tsx';
import { matchPageDataState } from '@atlassian/jira-servicedesk-insight-object-schema-page-store/src/services/selectors/index.tsx';
import { getLandingPageUrl } from '@atlassian/jira-servicedesk-insight-urls';
import { Link } from '@atlassian/react-resource-router';
import messages from './messages';

const SCHEMA_NAME_HEADER_HEIGHT = 88;
export const SCHEMA_NAME_HEADER_OFFSET_TOP = SCHEMA_NAME_HEADER_HEIGHT + 22;
export const ErrorSchemaNameTopHeader = () => null;
export const LoadingSchemaNameTopHeader = () => (
	<Skeleton isShimmering width="30%" height="28px" borderRadius="3px" />
);
export const LoadedSchemaNameTopHeader = () => {
	const [schemaPageData] = useLoadedSchemaPageData();
	const { objectSchema } = schemaPageData;

	return (
		<Heading size="large">
			<Box xcss={ellipsisStyles}>{objectSchema.name}</Box>
		</Heading>
	);
};
export const TopHeaderWithBreadcrumbs = () => {
	const { formatMessage } = useIntl();
	const [{ pageDataState }] = useInsightObjectSchemaPageData();
	return (
		<Box
			testId="servicedesk-insight-object-schema-page.common.ui.object-schema-page-layout.page-header.top-header"
			xcss={topHeaderStyle}
		>
			<Box xcss={breadCrumbsWrapper}>
				<Breadcrumbs label={formatMessage(messages.assetsTopHeaderBreadcrumbsA11Y)}>
					<BreadcrumbsItem
						testId="servicedesk-insight-object-schema-page.common.ui.object-schema-page-layout.page-header.breadcrumbs-item-assets"
						href={getLandingPageUrl()}
						text={formatMessage(messages.assetsBreadcrumbsItem)}
						component={Link}
					/>
					<BreadcrumbsItem
						testId="servicedesk-insight-object-schema-page.common.ui.object-schema-page-layout.page-header.breadcrumbs-item-schemas"
						href={getLandingPageUrl()}
						text={formatMessage(messages.schemasBreadcrumbsItem)}
						component={Link}
					/>
				</Breadcrumbs>
			</Box>
			<PageHeader disableTitleStyles>
				{matchPageDataState(pageDataState, {
					loading: () => <LoadingSchemaNameTopHeader />,
					error: () => <ErrorSchemaNameTopHeader />,
					success: () => <LoadedSchemaNameTopHeader />,
				})}
			</PageHeader>
		</Box>
	);
};
const breadCrumbsWrapper = xcss({
	marginTop: 'space.250',
	marginBottom: 'space.negative.150',
});

const topHeaderStyle = xcss({
	marginLeft: 'space.negative.200',
	height: `${SCHEMA_NAME_HEADER_HEIGHT}px`,
});
const ellipsisStyles = xcss({ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' });
