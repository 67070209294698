import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useObjectSearchPagination } from '@atlassian/jira-servicedesk-insight-object-search-store/src/controllers/store/index.tsx';
import type { ObjectsPaginationLabelProps } from '../../types';
import { messages } from './messages';

export const ObjectsPaginationLabel = ({ type = 'default' }: ObjectsPaginationLabelProps) => {
	const { formatMessage } = useIntl();

	const [{ numObjects, numPages, startIndex, toIndex }] = useObjectSearchPagination();

	const getMessage = () => {
		if (numPages > 1) {
			if (type === 'simplified') {
				return formatMessage(messages.paginationSimplified, {
					startIndex,
					toIndex,
					numObjects,
				});
			}
			return formatMessage(messages.pagination, {
				startIndex,
				toIndex,
				numObjects,
			});
		}
		return formatMessage(messages.objectsCount, {
			numObjects,
		});
	};

	return <>{getMessage()}</>;
};
