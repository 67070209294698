import React, { type ReactNode } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { CmdbAsyncActionsStoreContainer } from '../controllers';
import type { ContainerProps } from '../controllers/types';

type CmdbAsyncActionsContainer = Pick<ContainerProps, 'workspaceId'> & { children?: ReactNode };

export const CmdbAsyncActionsContainer = ({ workspaceId, children }: CmdbAsyncActionsContainer) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { showFlag, dismissFlag } = useFlagsService();
	const { formatMessage } = useIntl();

	return (
		<JSErrorBoundary
			id="CmdbAsyncActions"
			packageName="jiraServicedeskCmdbAsyncActions"
			fallback="flag"
		>
			<CmdbAsyncActionsStoreContainer
				isGlobal
				workspaceId={workspaceId}
				createAnalyticsEvent={createAnalyticsEvent}
				showFlag={showFlag}
				dismissFlag={dismissFlag}
				formatMessage={formatMessage}
			>
				{children}
			</CmdbAsyncActionsStoreContainer>
		</JSErrorBoundary>
	);
};
